import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export const AcEdit = ({ acid, modalClose, tableRefresh }) => {

    const [pop_name, setPop_name] = useState("")
    const [arduino_name, setArduino_name] = useState("")
    const [ac_id, setAc_id] = useState("")
    const [ac_description, setAc_description] = useState("")
    const [ac_status, setAc_status] = useState("")
    const [validationError, setValidationError] = useState({})

    useEffect(() => {
        fetchProduct()
    }, [])

    const fetchProduct = async () => {
        await axios.get(`https://pab.race.net.bd/api/acedit/${acid}`).then(({ data }) => {
            const { pop_name, arduino_name, ac_id, ac_description, ac_status } = data.ac
            setPop_name(pop_name)
            setArduino_name(arduino_name)
            setAc_id(ac_id)
            setAc_description(ac_description)
            setAc_status(ac_status)
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    const updatePop = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('pop_name', pop_name)
        formData.append('arduino_name', arduino_name)
        formData.append('ac_id', ac_id)
        formData.append('ac_description', ac_description)
        formData.append('ac_status', ac_status)

        await axios.post(`https://pab.race.net.bd/api/acupdate/${acid}`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            modalClose();
            tableRefresh();
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="form-wrapper">
            {
                Object.keys(validationError).length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger">
                                <ul className="mb-0">
                                    {
                                        Object.entries(validationError).map(([key, value]) => (
                                            <li key={key}>{value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
            <Form onSubmit={updatePop}>
                <Row>
                    <Col>
                        <Form.Group controlId="pop_id">
                            <Form.Label>Pop Name</Form.Label>
                            <Form.Control disabled type="text" value={pop_name} onChange={(event) => {
                                setPop_name(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="arduino_id">
                            <Form.Label>Arduino Name</Form.Label>
                            <Form.Control disabled type="text" value={arduino_name} onChange={(event) => {
                                setArduino_name(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="ac_id">
                            <Form.Label>Ac ID</Form.Label>
                            <Form.Control type="text" value={ac_id} onChange={(event) => {
                                setAc_id(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="ac_description">
                            <Form.Label>Ac Description</Form.Label>
                            <Form.Control type="text" value={ac_description} onChange={(event) => {
                                setAc_description(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" className="float-right" type="submit">
                    Update
                </Button>
            </Form>
        </div>
    )
}


