import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';

import { popAddFromValidation, popAddNameFromValidation } from '../../../../schema/Validation';

export const PoPAdd = () => {
    const [PoPNameData, setPoPNameData] = useState([]);
    const [aislesData, setAislesData] = useState([]);
    const [toggle, setToggle] = useState(false)

    useEffect(() => {
        fetchPoPName();
    }, [])

    const fetchPoPName = async () => {
        await axios.get(`https://pab.race.net.bd/api/popfetch`)
            .then((response) => {
                setPoPNameData(response.data);
            }).catch((error) => {
                Swal.fire({
                    text: error,
                    icon: "error"
                })
            })
    }


    const formikPoPName = useFormik({
        initialValues: {
            popName: '',
        },
        validationSchema: popAddNameFromValidation,

        onSubmit: (values, { resetForm }) => {
            const url = 'https://pab.race.net.bd/api/addpopname';
            const targetUrl = `${url}?popName=${values.popName}`;
            const promise = axios.post(targetUrl);
            promise
                .then((success) => {
                    if (success.data == 1) {
                        Swal.fire({
                            text: "PoP Name Already Exist",
                            icon: "error"
                        })
                    } else {
                        resetForm();
                        Swal.fire({
                            text: "PoP Name Created Successfully",
                            icon: "success"
                        })
                    }

                })
                .catch((error) => {
                    Swal.fire({
                        text: error.message,
                        icon: "error"
                    })
                });
        },

    });

    const formik = useFormik({
        initialValues: {
            pop_name: '',

            cold_aisle_1: 'Cold Aisle-1',
            cold_aisle_1_temperature_threshold: '',
            cold_aisle_1_humidity_threshold: '',

            cold_aisle_2: 'Cold Aisle-2',
            cold_aisle_2_temperature_threshold: '',
            cold_aisle_2_humidity_threshold: '',

            hot_aisle_1: 'Hot_Aisle_1',
            hot_aisle_1_temperature_threshold: '',
            hot_aisle_1_humidity_threshold: '',

            hot_aisle_2: 'Hot_Aisle_2',
            hot_aisle_2_temperature_threshold: '',
            hot_aisle_2_humidity_threshold: '',
        },
        validationSchema: popAddFromValidation,

        onSubmit: (values, { resetForm }) => {
            const url = 'https://pab.race.net.bd/api/addpop';
            const targetUrl = `${url}?pop_name=${values.pop_name}&cold_aisle_1=${1}&cold_aisle_1_temperature_threshold=${values.cold_aisle_1_temperature_threshold}&cold_aisle_1_humidity_threshold=${values.cold_aisle_1_humidity_threshold}&cold_aisle_2=${2}&cold_aisle_2_temperature_threshold=${values.cold_aisle_2_temperature_threshold}&cold_aisle_2_humidity_threshold=${values.cold_aisle_2_humidity_threshold}&hot_aisle_1=${3}&hot_aisle_1_temperature_threshold=${values.hot_aisle_1_temperature_threshold}&hot_aisle_1_humidity_threshold=${values.hot_aisle_1_humidity_threshold}&hot_aisle_2=${4}&hot_aisle_2_temperature_threshold=${values.hot_aisle_2_temperature_threshold}&hot_aisle_2_humidity_threshold=${values.hot_aisle_2_humidity_threshold}`;

            const promise = axios.post(targetUrl);
            promise
                .then((success) => {
                    if (success.data == 1) {
                        Swal.fire({
                            text: "PoP Name Already Exist",
                            icon: "error"
                        })
                    } else {
                        resetForm();
                        Swal.fire({
                            text: "Successfully Inserted",
                            icon: "success"
                        })
                    }

                })
                .catch((error) => {
                    Swal.fire({
                        text: error.message,
                        icon: "error"
                    })
                });
        }

    });

    return (

        <>
            <div className='row bg-white p-4 rounded mt-5'>
                <div className='col-md-12 mb-3'>
                    <button type='button' className='btn btn-primary text-white' onClick={() => { setToggle(!toggle); fetchPoPName(); }} >ADD Temperature & Humidity</button>
                </div>

                {toggle ?
                    <div className='col-md-12'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <select className="form-select form-control"
                                        name='pop_name' value={formik.values.pop_name} onChange={formik.handleChange}>
                                        <option value="">Select PoP Name</option>
                                        {PoPNameData && PoPNameData.map(item => (
                                            <option key={item.pop_id} value={item.pop_id}>{item.pop_name}</option>
                                        ))}
                                    </select>
                                    {formik.touched.pop_name && formik.errors.pop_name ? (
                                        <div className='text-danger'>{formik.errors.pop_name}</div>
                                    ) : null}
                                </div>
                                <div className='col-md-9'>
                                    <div className='row mb-3'>
                                        <div className='col-md-4'>
                                            <input type="text" className="form-control" name='aisle' placeholder='Cold Aisle-1'
                                                value={formik.values.cold_aisle_1}
                                                onChange={formik.handleChange} disabled />
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='cold_aisle_1_temperature_threshold' placeholder='Temperature Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.cold_aisle_1_temperature_threshold} />
                                            </div>
                                            {formik.touched.cold_aisle_1_temperature_threshold && formik.errors.cold_aisle_1_temperature_threshold ? (
                                                <div className='text-danger'>{formik.errors.cold_aisle_1_temperature_threshold}</div>
                                            ) : null}
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='cold_aisle_1_humidity_threshold' placeholder='Humidity Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.cold_aisle_1_humidity_threshold} />
                                            </div>
                                            {formik.touched.cold_aisle_1_humidity_threshold && formik.errors.cold_aisle_1_humidity_threshold ? (
                                                <div className='text-danger'>{formik.errors.cold_aisle_1_humidity_threshold}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-md-4'>
                                            <input type="text" className="form-control" name='aisle' placeholder='Cold Aisle-2'
                                                value={formik.values.cold_aisle_2}
                                                onChange={formik.handleChange} disabled />
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='cold_aisle_2_temperature_threshold' placeholder='Temperature Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.cold_aisle_2_temperature_threshold} />
                                            </div>
                                            {formik.touched.cold_aisle_2_temperature_threshold && formik.errors.cold_aisle_2_temperature_threshold ? (
                                                <div className='text-danger'>{formik.errors.cold_aisle_2_temperature_threshold}</div>
                                            ) : null}
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='cold_aisle_2_humidity_threshold' placeholder='Humidity Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.cold_aisle_2_humidity_threshold} />
                                            </div>
                                            {formik.touched.cold_aisle_2_humidity_threshold && formik.errors.cold_aisle_2_humidity_threshold ? (
                                                <div className='text-danger'>{formik.errors.cold_aisle_2_humidity_threshold}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-md-4'>
                                            <input type="text" className="form-control" name='aisle' placeholder='Hot_Aisle_1'
                                                value={formik.values.hot_aisle_1}
                                                onChange={formik.handleChange} disabled />
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='hot_aisle_1_temperature_threshold' placeholder='Temperature Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.hot_aisle_1_temperature_threshold} />
                                            </div>
                                            {formik.touched.hot_aisle_1_temperature_threshold && formik.errors.hot_aisle_1_temperature_threshold ? (
                                                <div className='text-danger'>{formik.errors.hot_aisle_1_temperature_threshold}</div>
                                            ) : null}
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='hot_aisle_1_humidity_threshold' placeholder='Humidity Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.hot_aisle_1_humidity_threshold} />
                                            </div>
                                            {formik.touched.hot_aisle_1_humidity_threshold && formik.errors.hot_aisle_1_humidity_threshold ? (
                                                <div className='text-danger'>{formik.errors.hot_aisle_1_humidity_threshold}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-md-4'>
                                            <input type="text" className="form-control" name='aisle' placeholder='Hot_Aisle-2'
                                                value={formik.values.hot_aisle_2}
                                                onChange={formik.handleChange} disabled />
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='hot_aisle_2_temperature_threshold' placeholder='Temperature Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.hot_aisle_2_temperature_threshold} />
                                            </div>
                                            {formik.touched.hot_aisle_2_temperature_threshold && formik.errors.hot_aisle_2_temperature_threshold ? (
                                                <div className='text-danger'>{formik.errors.hot_aisle_2_temperature_threshold}</div>
                                            ) : null}
                                        </div>

                                        <div className='col-md-4'>
                                            <div className="">
                                                <input type="text" className="form-control" name='hot_aisle_2_humidity_threshold' placeholder='Humidity Threshold'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.hot_aisle_2_humidity_threshold} />
                                            </div>
                                            {formik.touched.hot_aisle_2_humidity_threshold && formik.errors.hot_aisle_2_humidity_threshold ? (
                                                <div className='text-danger'>{formik.errors.hot_aisle_2_humidity_threshold}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <button type='submit' className='btn btn-primary text-white'>ADD</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    :
                    <div className='col-md-12'>
                        <form onSubmit={formikPoPName.handleSubmit}>
                            <div className='row'>
                                <div className='col-md-10'>
                                    <div className="">
                                        <input type="text" className="form-control" name='popName' placeholder='PoP Name'
                                            onChange={formikPoPName.handleChange}
                                            value={formikPoPName.values.popName} />
                                    </div>

                                    {formikPoPName.touched.popName && formikPoPName.errors.popName ? (
                                        <div className='text-danger'>{formikPoPName.errors.popName}</div>
                                    ) : null}
                                </div>
                                <div className='col-md-2'>
                                    <button type='submit' className='btn btn-primary text-white w-100'>ADD</button>
                                </div>

                            </div>
                        </form>
                    </div>}
            </div>
        </>



    );
}

export default PoPAdd;
