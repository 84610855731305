import React, { useState } from 'react';
import { Formik, Form, resetForm } from 'formik';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { FaUserAlt, FaLock } from "react-icons/fa";
import { AiOutlineMail, AiFillPhone } from "react-icons/ai";
import { IoIosNotifications } from 'react-icons/io';
import { GrStatusGood } from 'react-icons/gr';
import { GoTasklist } from 'react-icons/go';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Register = () => {
        const [formData, setFormData] = useState({
            name: "",
            username: "",
            email: "",
            mobile: "",
            role: "",
            status: "",
            is_email_notification: "",
            password: "",
        });

        const paramName = formData.name;
        const paramUsername = formData.username;
        const paramEmail = formData.email;
        const paramMobile = formData.mobile;
        const paramRole = formData.role;
        const paramStatus = formData.status;
        var paramIs_email_notification = formData.is_email_notification;
        const paramPassword = formData.password;

        const url = 'https://pab.race.net.bd/api/userregister';
    
        const targetUrl = `${url}?name=${paramName}&username=${paramUsername}&email=${paramEmail}&mobile=${paramMobile}&role=${paramRole}&status=${paramStatus}&is_email_notification=${paramIs_email_notification}&password=${paramPassword}`;
    
       
        const handleSubmit = (e) => {
            e.preventDefault();
            const promise = axios.post(targetUrl);
        const success = (response) => {
            if (response.data === 'success') {
                toast.success('Registration Successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                setFormData({
                    name: "",
                    username: "",
                    email: "",
                    mobile: "",
                    role: "",
                    status: "",
                    is_email_notification: "",
                    password: "",
                });
                
            } else if (response.data === 'failed'){
                toast.error('Registration Failed', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });    
            }
          
        }
    
        const error = (error) => {
          console.log(error);
        }
    
        promise
        .then(success)
        .catch(error);
        }

        return(
    
                <Formik 
                    initialValues={{
                        name: "",
                        username: "",
                        email: "",
                        mobile: "",
                        role: "",
                        status: "",
                        is_email_notification: "",
                        password: ""
                    }}
                    
                
                >
                    {(formikprops) => {
                        return (

                            <Form onSubmit={handleSubmit}>
                                <div className="container-fluid" >
                                    <div className="row d-flex justify-content-center align-items-center" style={{height:"78vh"}}>
                                        <div className="col-md-5 rounded">
                                            <h2 className='text-center'>User Registration</h2>
                                        <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><FaUserAlt /></span>
                                            <input type="text" className="form-control" placeholder="Full name" onChange={ (e) =>  setFormData({...formData, name:e.target.value}) } value={formData.name}/>
                                        </div>    
                                        <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><FaUserAlt /></span>
                                            <input type="text" className="form-control" placeholder="Username" onChange={ (e) =>  setFormData({...formData, username:e.target.value}) } value={formData.username}/>
                                        </div>
                                        <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><AiOutlineMail /></span>
                                            <input type="text" className="form-control" placeholder="Email" onChange={ (e) =>  setFormData({...formData, email:e.target.value}) } value={formData.email}/>
                                        </div>
                                        <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><AiFillPhone /></span>
                                            <input type="text" className="form-control" placeholder="Mobile" onChange={ (e) =>  setFormData({...formData, mobile:e.target.value}) } value={formData.mobile}/>
                                        </div>
                                        {/* <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><AiOutlineMail /></span>
                                            <input type="text" className="form-control" placeholder="Role" onChange={ (e) =>  setFormData({...formData, role:e.target.value}) } value={formData.role}/>
                                        </div> */}
                                        <div className='input-group flex-nowrap mb-3'>
                                        <span className="input-group-text" id="addon-wrapping"><GoTasklist /></span>
                                                <select className="form-select form-control" name='role' 
                                                onChange={(e)=>{
                                                    setFormData({...formData, role:e.target.value})
                                                }}
                                                value={formData.role}
                                                >
                                                    <option selected>Select Role</option>
                                                    <option value="Super Admin">Super Admin</option>
                                                    <option value="Admin">Admin</option> 
                                                    <option value="Guest">Guest</option>
                                                </select>
                                        </div>

                                        <div className='input-group flex-nowrap mb-3'>
                                        <span className="input-group-text" id="addon-wrapping"><GrStatusGood /></span>
                                                <select className="form-select form-control" name='role' 
                                               onChange={ (e) =>  setFormData({...formData, status:e.target.value}) } 
                                               value={formData.status}
                                                >
                                                    <option selected>Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option> 
                                                </select>
                                        </div>
                                        {/* <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><GrStatusGood /></span>
                                            <input type="text" className="form-control" placeholder="Status" onChange={ (e) =>  setFormData({...formData, status:e.target.value}) } value={formData.status}/>
                                        </div> */}
                                        
                                        <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text" id="addon-wrapping"><FaLock /></span>
                                            <input type="password" className="form-control" placeholder="Password" onChange={ (e) => setFormData({...formData, password:e.target.value})} value={formData.password}/>
                                        </div>
                                        <div className="input-group flex-nowrap mb-3">
                                            <span className="input-group-text mr-4" id="addon-wrapping">Email Notification</span>
                                            {/* <input type="checkbox" className="form-control" onChange={ (e) =>  setFormData({...formData, is_email_notification:e.target.value}) } value={formData.is_email_notification}/> */}
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" onChange={ (e) =>  setFormData({...formData, is_email_notification:e.target.value}) } value="1"/>
                                                <label class="form-check-label" for="inlineRadio1">Yes</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" onChange={ (e) => setFormData({...formData, is_email_notification:e.target.value}) } value="0"/>
                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                            </div>

                                        </div>
                                        <div className='d-flex justify-content-end'>
                                        <button type="submit" className="btn btn-success">Register</button>
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </Form>    
                        )
                    }}
                </Formik>

          )       
      }
  export default Register
