import React from 'react';

export const TableHeader = ({ columns, apicall, apicallarduino, apicallac, apicallups, apicallgenerator, apicallphase }) => {

  return (
    <>
      {apicall ? <thead>
        <tr>
          <th rowSpan={2}>#</th>
          <th rowSpan={2}>PoP Name</th>
          <th colSpan={4}>Temperature Threshold</th>
          <th colSpan={4}>Humidity Threshold</th>
          <th rowSpan={2}>Action</th>
        </tr>
        <tr>
          <th>Cold Aisle-1</th>
          <th>Cold Aisle-2</th>
          <th>Hot Aisle-1</th>
          <th>Hot Aisle-2</th>

          <th>Cold Aisle-1</th>
          <th>Cold Aisle-2</th>
          <th>Hot Aisle-1</th>
          <th>Hot Aisle-2</th>
        </tr>
      </thead> : <thead>
        <tr>
          {columns && columns.map((column, key) => (
            <th key={key}>{column}</th>
          ))}
        </tr>
      </thead>}
    </>
  )
}
