import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';


function Header() {
  const navigate = useNavigate();
      return (
         <div>
<nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}

   <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
    </li>
  </ul>

{/* Rigth navbar links */}
  <ul className="navbar-nav ml-auto">
    <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        <FiLogOut />
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <button type="submit" onClick={()=>{localStorage.clear(); navigate("/")}} className="dropdown-item dropdown-header">Logout</button>
      </div>
    </li>  
  </ul>
</nav>
</div>

      )
  }
  export default Header;
