import React, { Component } from 'react'


function Footer() {
  return (
    <div>
      <footer className="main-footer text-center">
        <strong>Copyright © 2023 <a href="https://www.race.net.bd/">Race Online Ltd</a>. </strong>
        All rights reserved.
      </footer>
    </div>

  )
}

export default Footer;