import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import { PhaseAddFromValidation } from '../../../../schema/Validation';


export const PhaseAdd = () => {
    const [dropDownValue, setDropDownValue] = useState([]);
    const [dropDownValueArduino, setDropDownValueArduino] = useState([]);
    const [arduinolist, setArduino] = useState([]);

    const formik = useFormik({
        initialValues: {
            pop_id: '',
            arduino_id: '',
            phase_id: '',
            phase_description: '',
            phase_status: '',
        },
        validationSchema: PhaseAddFromValidation,

        onSubmit: (values, { resetForm }) => {
            const url = 'https://pab.race.net.bd/api/addphase';
            const targetUrl = `${url}?pop_id=${values.pop_id}&arduino_id=${values.arduino_id}&phase_id=${values.phase_id}&phase_description=${values.phase_description}&phase_status=${1}`;
            const promise = axios.post(targetUrl);
            promise
                .then((success) => {
                    if (success.data == 1) {
                        Swal.fire({
                            text: "Phase Already Exist",
                            icon: "error"
                        })
                    } else {
                        resetForm();
                        Swal.fire({
                            text: "Phase Added Successfully",
                            icon: "success"
                        })
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        text: error.message,
                        icon: "error"
                    })
                });
        },

    });

    const fetchDropDownData = async () => {
        await axios.get(`https://pab.race.net.bd/api/popfetch`).then(({ data }) => {
            setDropDownValue(data);

        })
    }

    useEffect(() => {
        fetchDropDownData();
    }, []);



    useEffect(() => {
        if (!formik.values.pop_id) return;

        axios.get(`https://pab.race.net.bd/api/arduinofetchforac/${formik.values.pop_id}`)
            .then(res => setArduino(res.data))
            .catch(err => console.error(err));
    }, [formik.values.pop_id]);


    return (
        <form className='bg-white pt-4 px-4 rounded mt-5' onSubmit={formik.handleSubmit}>
            <div className='row'>
                <div className='col-md-3'>
                    <div className="mb-4">
                        <select className="form-select form-control" name='pop_id' value={formik.values.pop_id} onChange={formik.handleChange}>
                            <option selected value="">--Select PoP--</option>
                            {dropDownValue.length > 0 && dropDownValue.map((option, index) => (
                                <option key={index} value={option.pop_id}>{option.pop_name}</option>
                            ))}
                        </select>
                    </div>
                    {formik.touched.pop_id && formik.errors.pop_id ? (
                        <div className='text-danger'>{formik.errors.pop_id}</div>
                    ) : null}
                </div>

                <div className='col-md-3'>
                    <div className="mb-4">

                        <select
                            id="arduino_id"
                            name="arduino_id"
                            className="form-select form-control"
                            value={formik.values.arduino_id}
                            onChange={formik.handleChange}
                        >
                            <option value="">--Select Arduino--</option>
                            {arduinolist.map(arduino_id => (
                                <option key={arduino_id.arduino_id} value={arduino_id.arduino_id}>{arduino_id.arduino_name}</option>
                            ))}
                        </select>
                    </div>
                    {formik.touched.arduino_id && formik.errors.arduino_id ? (
                        <div className='text-danger'>{formik.errors.arduino_id}</div>
                    ) : null}
                </div>


                <div className='col-md-2'>
                    <div className="mb-4">
                        <select
                            id="phase_id"
                            name="phase_id"
                            className="form-select form-control"
                            value={formik.values.phase_id}
                            onChange={formik.handleChange}
                        >
                            <option selected value="">--Phase ID--</option>
                            <option value="1">1</option>
                        </select>
                    </div>
                    {formik.touched.phase_id && formik.errors.phase_id ? (
                        <div className='text-danger'>{formik.errors.phase_id}</div>
                    ) : null}
                </div>


                <div className='col-md-2'>
                    <div className="mb-4">
                        <input type="text" className="form-control" name='phase_description' placeholder='Capacity in Amp'
                            onChange={formik.handleChange}
                            value={formik.values.phase_description} />
                    </div>
                    {formik.touched.phase_description && formik.errors.phase_description ? (
                        <div className='text-danger'>{formik.errors.phase_description}</div>
                    ) : null}
                </div>

                <div className='col-md-2'>
                    <button type='submit' className='btn btn-primary text-white w-100'>ADD</button>
                </div>
            </div>
        </form>
    );
}

export default PhaseAdd;

