//SAVE

import React, { useEffect, useState, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from 'react-icons/si';
import axios from 'axios';
import { Link } from 'react-router-dom';


export function AlarmPopDetailsTable() {

    const tableRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const role = sessionStorage.getItem('role');

    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const fetchProducts = async () => {
        await axios.get(`https://pab.race.net.bd/api/alarmpopcurrentstatus`).then(({ data }) => {
            setProducts(data)
        })
    }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className='text-center p-2' style={{ background: '#96abc1' }}>
                        <h4 className='m-0 fw-bolder' style={{ fontSize: '18px', color: 'white' }}>PoP Alarm</h4>
                    </div>
                </div>

                <div className='col-md-12 text-center'>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </div>

                <div className='col-md-12'>
                    <div class="table-responsive" style={{ height: '60vh', overflowY: 'auto' }}>
                        <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>PoP Name</th>
                                    <th colSpan={4}>Temperature (°C)</th>
                                    <th colSpan={4}>Humidity (%)</th>
                                    <th rowSpan={2}>Electricity</th>
                                    <th colSpan={2}>Generator</th>
                                    <th colSpan={4}>UPS</th>
                                    <th colSpan={4}>Air Condition</th>
                                    <th rowSpan={2}>Phase Sequence</th>
                                    <th rowSpan={2}>PoP Status</th>
                                    <th rowSpan={2}>Last Updated</th>
                                </tr>
                                <tr>
                                    <th>Aisle_Cold_1</th>
                                    <th>Aisle_Cold_2</th>
                                    <th>Aisle_Hot_1</th>
                                    <th>Aisle_Hot_2</th>
                                    <th>Aisle_Cold_1</th>
                                    <th>Aisle_Cold_2</th>
                                    <th>Aisle_Hot_1</th>
                                    <th>Aisle_Hot_2</th>
                                    <th>G_1</th>
                                    <th>G_2</th>
                                    <th>UPS_1</th>
                                    <th>UPS_2</th>
                                    <th>UPS_3</th>
                                    <th>UPS_4</th>
                                    <th>AC_1</th>
                                    <th>AC_2</th>
                                    <th>AC_3</th>
                                    <th>AC_4</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    products && (
                                        products.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                {role && role === 'Guest' ? `${row.pop_name}` :
                                                    <td><Link className="nav-link" to={`/popdashboard/${row.pop_id}`}>{row.pop_name}</Link></td>}
                                                {parseInt(row.aisle_cold_1_temperature) >= parseInt(row.cold_aisle_1_temperature_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_cold_1_temperature}</td> : <td>{row.aisle_cold_1_temperature}</td>}
                                                {parseInt(row.aisle_cold_2_temperature) >= parseInt(row.cold_aisle_2_temperature_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_cold_2_temperature}</td> : <td>{row.aisle_cold_2_temperature}</td>}

                                                {parseInt(row.aisle_hot_1_temperature) >= parseInt(row.hot_aisle_1_temperature_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_hot_1_temperature}</td> : <td>{row.aisle_hot_1_temperature}</td>}
                                                {parseInt(row.aisle_hot_2_temperature) >= parseInt(row.hot_aisle_2_temperature_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_hot_2_temperature}</td> : <td>{row.aisle_hot_2_temperature}</td>}

                                                {/*Humidity*/}
                                                {parseInt(row.aisle_cold_1_humidity) >= parseInt(row.cold_aisle_1_humidity_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_cold_1_humidity}</td> : <td>{row.aisle_cold_1_humidity}</td>}
                                                {parseInt(row.aisle_cold_2_humidity) >= parseInt(row.cold_aisle_2_humidity_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_cold_2_humidity}</td> : <td>{row.aisle_cold_2_humidity}</td>}

                                                {parseInt(row.aisle_hot_1_humidity) >= parseInt(row.hot_aisle_1_humidity_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_hot_1_humidity}</td> : <td>{row.aisle_hot_1_humidity}</td>}
                                                {parseInt(row.aisle_hot_2_humidity) >= parseInt(row.hot_aisle_2_humidity_threshold) ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_hot_2_humidity}</td> : <td>{row.aisle_hot_2_humidity}</td>}

                                                {row.electricity === "N/A" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.electricity}</td> : <td>{row.electricity}</td>}

                                                {row.generator_1_status === "Standby" ? <td style={{ background: 'yellow', fontWeight: 'bolder' }}>{row.generator_1_status}</td> : <td>{row.generator_1_status}</td>}
                                                {row.generator_2_status === "Standby" ? <td style={{ background: 'yellow', fontWeight: 'bolder' }}>{row.generator_2_status}</td> : <td>{row.generator_2_status}</td>}

                                                {row.ups_1_status === "Down" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.ups_1_status}</td> : <td>{row.ups_1_status}</td>}
                                                {row.ups_2_status === "Down" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.ups_2_status}</td> : <td>{row.ups_2_status}</td>}
                                                {row.ups_3_status === "Down" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.ups_3_status}</td> : <td>{row.ups_3_status}</td>}
                                                {row.ups_4_status === "Down" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.ups_4_status}</td> : <td>{row.ups_4_status}</td>}


                                                {row.ac_1_status === "Standby" ? <td style={{ background: 'yellow', fontWeight: 'bolder' }}>{row.ac_1_status}</td> : <td>{row.ac_1_status}</td>}
                                                {row.ac_2_status === "Standby" ? <td style={{ background: 'yellow', fontWeight: 'bolder' }}>{row.ac_2_status}</td> : <td>{row.ac_2_status}</td>}
                                                {row.ac_3_status === "Standby" ? <td style={{ background: 'yellow', fontWeight: 'bolder' }}>{row.ac_3_status}</td> : <td>{row.ac_3_status}</td>}
                                                {row.ac_4_status === "Standby" ? <td style={{ background: 'yellow', fontWeight: 'bolder' }}>{row.ac_4_status}</td> : <td>{row.ac_4_status}</td>}

                                                {row.phase_status === "Corrected" ? <td style={{ background: 'green', color: 'white', fontWeight: 'bolder' }}>{row.phase_status}</td> : <td>{row.phase_status}</td>}

                                                {row.status === "Alarm" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.status}</td> : <td>{row.status}</td>}

                                                {row.Last_Updated === "Alarm" ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder', width: '200px', display: 'inline-block' }}>{row.updated_at}</td> : <td style={{ width: '200px', display: 'inline-block' }}>{row.updated_at}</td>}
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-3 float-right'>
                        <DownloadTableExcel
                            filename="PoP Data"
                            sheet="Data"
                            currentTableRef={tableRef.current}
                        >
                            <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                        </DownloadTableExcel>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AlarmPopDetailsTable;