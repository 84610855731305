import React, { useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { SiMicrosoftexcel } from 'react-icons/si';
import './CustomizeTable.css';


export const CutomizeTable = ({ columns, search, apicall, apicallarduino, apicallac, apicallups, apicallgenerator, apicallphase }) => {
  const tableRef = useRef(null);
  return (
    <div class="table-responsive" style={{ height: '60vh', overflowY: 'auto' }}>
      <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
        <TableHeader
          columns={columns}
          apicall={apicall}
          apicallarduino={apicallarduino}
          apicallac={apicallac}
          apicallups={apicallups}
          apicallgenerator={apicallgenerator}
          apicallphase={apicallphase} />

        <TableBody
          search={search}
          apicall={apicall}
          apicallarduino={apicallarduino}
          apicallac={apicallac}
          apicallups={apicallups}
          apicallgenerator={apicallgenerator}
          apicallphase={apicallphase}
        />
      </table>

      <div className='mt-3 float-right'>
        <DownloadTableExcel
          filename="PoP Data"
          sheet="Data"
          currentTableRef={tableRef.current}
          fileExtension="xlsx"
        >
          <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
        </DownloadTableExcel>
      </div>
    </div>
  )
}
