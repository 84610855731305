import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ArduinoAddFromValidation } from '../../../../schema/Validation';

export const ArduinoAdd = () => {
    // const [arduinoStore, setProducts] = useState([]);
    const [dropDownValue, setDropDownValue] = useState([]);

    const formik = useFormik({
        initialValues: {
            pop_id: '',
            arduino_name: '',
            arduino_status: '',
        },
        validationSchema: ArduinoAddFromValidation,
        onSubmit: (values, { resetForm }) => {
            const url = 'https://pab.race.net.bd/api/addarduino';
            const targetUrl = `${url}?pop_id=${values.pop_id}&arduino_name=${values.arduino_name}&arduino_status=${1}`;
            const promise = axios.post(targetUrl);
            promise
                .then((success) => {
                    if (success.data == 1) {
                        Swal.fire({
                            text: "Device Name Already Exist",
                            icon: "error"
                        })
                    } else {
                        resetForm();
                        Swal.fire({
                            text: "Arduino Added Successfully",
                            icon: "success"
                        })
                    }

                })
                .catch((error) => {
                    Swal.fire({
                        text: error.message,
                        icon: "error"
                    })
                });
        },

    });


    const fetchDropDownData = async () => {
        await axios.get(`https://pab.race.net.bd/api/popfetch`).then(({ data }) => {
            setDropDownValue(data);
        })
    }

    useEffect(() => {
        fetchDropDownData();
    }, []);

    return (
        <form className='bg-white p-4 rounded mt-5' onSubmit={formik.handleSubmit}>
            <div className='row'>
                <div className='col-md-5'>
                    <div className="">
                        <select className="form-select form-control" name='pop_id' value={formik.values.pop_id} onChange={formik.handleChange}>
                            <option selected>---Select PoP---</option>
                            {dropDownValue.length > 0 && dropDownValue.map((option, index) => (
                                <option key={index} value={option.pop_id}>{option.pop_name}</option>

                            ))
                            }

                        </select>
                    </div>
                    {formik.touched.pop_id && formik.errors.pop_id ? (
                        <div className='text-danger'>{formik.errors.pop_id}</div>
                    ) : null}
                </div>

                <div className='col-md-5'>
                    <div className="">
                        <input type="text" className="form-control" name='arduino_name' placeholder='Device Name'
                            onChange={formik.handleChange}
                            value={formik.values.arduino_name} />
                    </div>
                    {formik.touched.arduino_name && formik.errors.arduino_name ? (
                        <div className='text-danger'>{formik.errors.arduino_name}</div>
                    ) : null}
                </div>
                <div className='col-md-2'>
                    <button type='submit' className='btn btn-primary text-white w-100' >ADD</button>
                </div>
            </div>
        </form>

    )

}


