import React, { useEffect, useState, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from 'react-icons/si';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { useFormik, Formik, Field, Form, ErrorMessage } from 'formik';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { ReportsLogFromValidation } from '../../../schema/Validation';

export const Log = () => {
    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [dropDownValue, setDropDownValue] = useState([]);
    const [acLog, setACLog] = useState([]);
    const [upsLog, setUpsLog] = useState([]);
    const [generatorLog, setGeneratorLog] = useState([]);
    const [electricityLog, setElectricityLog] = useState([]);
    const [humidityLog, setHumidityLog] = useState([]);
    const [temperatureLog, setTemperatureLog] = useState([]);
    const [phaseLog, setPhaseLog] = useState([]);


    const [searchValue, setSearchValue] = useState('');
    const [formDate, setFormDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }

    const formik = useFormik({
        initialValues: {
            popname: '',
            devicelist: '',
            formDate: formDate,
            toDate: toDate,
        },
        validationSchema: ReportsLogFromValidation,
        onSubmit: (values, { resetForm }) => {

            setIsLoading(true);
            const url = 'https://pab.race.net.bd/api/reportlog';
            const targetUrl = `${url}?pop_id=${values.popname}&device_list=${values.devicelist}&form_date=${moment(formDate).format('YYYY-MM-DD')}&to_date=${moment(toDate).format('YYYY-MM-DD')}`;
            const promise = axios.post(targetUrl);
            promise
                .then(({ data }) => {
                    if (data.acstatus === 200) {
                        setACLog(data.ac);
                        setUpsLog("");
                        setGeneratorLog("");
                        setElectricityLog("");
                        setHumidityLog("");
                        setTemperatureLog("");
                        setPhaseLog("");
                    } else if (data.upsstatus === 200) {
                        setUpsLog(data.ups);
                        setACLog("");
                        setGeneratorLog("");
                        setElectricityLog("");
                        setHumidityLog("");
                        setTemperatureLog("");
                        setPhaseLog("");
                    } else if (data.generatorstatus === 200) {
                        setGeneratorLog(data.generator);
                        setACLog("");
                        setUpsLog("");
                        setElectricityLog("");
                        setHumidityLog("");
                        setTemperatureLog("");
                        setPhaseLog("");
                    } else if (data.electricitystatus === 200) {
                        setElectricityLog(data.electricity);
                        setACLog("");
                        setUpsLog("");
                        setGeneratorLog("");
                        setHumidityLog("");
                        setTemperatureLog("");
                        setPhaseLog("");
                    } else if (data.humiditystatus === 200) {
                        setHumidityLog(data.humidity);
                        setACLog("");
                        setUpsLog("");
                        setGeneratorLog("");
                        setElectricityLog("");
                        setTemperatureLog("");
                        setPhaseLog("");
                    } else if (data.temperaturestatus === 200) {
                        setTemperatureLog(data.temperature);
                        setACLog("");
                        setUpsLog("");
                        setGeneratorLog("");
                        setElectricityLog("");
                        setHumidityLog("");
                        setPhaseLog("");

                    } else if (data.phasestatus === 200) {
                        setPhaseLog(data.phase);
                        setACLog("");
                        setUpsLog("");
                        setGeneratorLog("");
                        setElectricityLog("");
                        setHumidityLog("");
                        setTemperatureLog("");
                    }
                    setIsLoading(false);
                    resetForm();
                })
                .catch((error) => {
                    setIsLoading(false);
                    Swal.fire({
                        text: error.message,
                        icon: "error"
                    })
                });
        },
    });

    const handleFormDateChange = (date) => {
        formik.setFieldValue('formDate', date);
        setFormDate(date);
    };
    const handleToDateChange = (date) => {
        formik.setFieldValue('toDate', date);
        setToDate(date);
    };

    const fetchDropDownData = async () => {
        await axios.get(`https://pab.race.net.bd/api/popfetch`).then(({ data }) => {
            setDropDownValue(data);
        })
    }

    useEffect(() => {
        fetchDropDownData();
    }, []);


    return (
        <>
            <div className='container-fluid mb-4'>
                <form className='bg-white p-4 rounded' onSubmit={formik.handleSubmit}>
                    <div className='row'>
                        <div className='col-md-3'>
                            <select className="form-select form-control" name='popname'
                                value={formik.values.popname}
                                onChange={formik.handleChange}>
                                <option selected>---Select PoP---</option>
                                {dropDownValue.length > 0 && dropDownValue.map((option, index) => (
                                    <option key={index} value={option.pop_id}>{option.pop_name}</option>

                                ))
                                }
                            </select>
                            {formik.touched.popname && formik.errors.popname ? (
                                <div className='text-danger'>{formik.errors.popname}</div>
                            ) : null}
                        </div>
                        <div className='col-md-3'>
                            <select className="form-select form-control" name='devicelist'
                                value={formik.values.devicelist}
                                onChange={formik.handleChange}>
                                <option selected>--Select Log Item--</option>
                                <option value="ac">AC</option>
                                <option value="ups">UPS</option>
                                <option value="generator">Generator</option>
                                <option value="electricity">Electricity</option>
                                <option value="temperature">Temperature</option>
                                <option value="humidity">Humidity</option>
                                <option value="phase">Phase</option>
                            </select>
                            {formik.touched.devicelist && formik.errors.devicelist ? (
                                <div className='text-danger'>{formik.errors.devicelist}</div>
                            ) : null}
                        </div>

                        <div className='col-md-2'>
                            <div className='row'>
                                <div className='col-md-2 px-0' style={{ lineHeight: '30px' }}>Form</div>
                                <div className='col-md-10'>
                                    <DatePicker
                                        id="formdate"
                                        name="formdate"
                                        className="form-control"
                                        selected={formDate}
                                        onChange={handleFormDateChange}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    {formik.touched.formDate && formik.errors.formDate ? (
                                        <div className='text-danger'>{formik.errors.formDate}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <div className='row'>
                                <div className='col-md-2 px-0' style={{ lineHeight: '30px', textAlign: 'center' }}>To</div>
                                <div className='col-md-10'>
                                    <DatePicker
                                        id="todate"
                                        name="todate"
                                        className="form-control"
                                        selected={toDate}
                                        onChange={handleToDateChange}
                                        dateFormat="dd/MM/yyyy"
                                    />

                                    {formik.touched.toDate && formik.errors.toDate ? (
                                        <div className='text-danger'>{formik.errors.toDate}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            {isLoading ? <button class="btn btn-primary w-100" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Searching...
                            </button> : <button className='btn btn-primary w-100' type='submit'>
                                Search
                            </button>}
                        </div>
                    </div>
                </form >
            </div >


            {acLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>AC Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>#</th>
                                            <th rowSpan={2}>PoP Name</th>
                                            <th rowSpan={2}>Arduino Name</th>
                                            <th colSpan={4}>Air Condition</th>
                                            <th rowSpan={2}>Date Time</th>
                                        </tr>
                                        <tr>
                                            <th>AC-1</th>
                                            <th>AC-2</th>
                                            <th>AC-3</th>
                                            <th>AC-4</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {acLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.ac_1_status}</td>
                                                <td>{row.ac_2_status}</td>
                                                <td>{row.ac_3_status}</td>
                                                <td>{row.ac_4_status}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="PoP Data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>
                    </div>
                </div>}


            {upsLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>UPS Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th rowSpan={3}>#</th>
                                            <th rowSpan={3}>PoP Name</th>
                                            <th rowSpan={3}>Arduino Name</th>
                                            <th colSpan={8}>UPS</th>
                                            <th rowSpan={3}>Date Time</th>
                                        </tr>
                                        <tr>
                                            <th colSpan={2}>UPS-1</th>
                                            <th colSpan={2}>UPS-2</th>
                                            <th colSpan={2}>UPS-3</th>
                                            <th colSpan={2}>UPS-4</th>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <th>Description</th>

                                            <th>Status</th>
                                            <th>Description</th>

                                            <th>Status</th>
                                            <th>Description</th>

                                            <th>Status</th>
                                            <th>Description</th>
                                        </tr>

                                    </thead>
                                    <tbody>

                                        {upsLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.ups_1_status}</td>
                                                <td>{row.ups_1_description}</td>
                                                <td>{row.ups_2_status}</td>
                                                <td>{row.ups_2_description}</td>
                                                <td>{row.ups_3_status}</td>
                                                <td>{row.ups_3_description}</td>
                                                <td>{row.ups_4_status}</td>
                                                <td>{row.ups_4_description}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}


                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="PoP Data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>

                    </div>


                </div>
            }
            {generatorLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>Generator Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>#</th>
                                            <th rowSpan={2}>PoP Name</th>
                                            <th rowSpan={2}>Arduino Name</th>
                                            <th colSpan={2}>Generator</th>
                                            <th rowSpan={2}>Date Time</th>
                                        </tr>
                                        <tr>
                                            <th>G-1</th>
                                            <th>G-2</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {generatorLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.generator_1_status}</td>
                                                <td>{row.generator_2_status}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}


                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="PoP Data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>

                    </div>


                </div>
            }

            {electricityLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>Electricity Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>PoP Name</th>
                                            <th>Arduino Name</th>
                                            <th>Electricity</th>
                                            <th>Date Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {electricityLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.electricity}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}


                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="PoP Data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>

                    </div>


                </div>
            }
            {humidityLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>Humidity Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>#</th>
                                            <th rowSpan={2}>PoP Name</th>
                                            <th rowSpan={2}>Arduino Name</th>
                                            <th colSpan={4}>Humidity</th>
                                            <th rowSpan={2}>Date Time</th>
                                        </tr>
                                        <tr>
                                            <th>Aisle_Cold_1</th>
                                            <th>Aisle_Cold_2</th>
                                            <th>Aisle_Hot_1</th>
                                            <th>Aisle_Hot_2</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {humidityLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.aisle_cold_1_humidity}</td>
                                                <td>{row.aisle_cold_2_humidity}</td>
                                                <td>{row.aisle_hot_1_humidity}</td>
                                                <td>{row.aisle_hot_2_humidity}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}


                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="PoP Data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>

                    </div>


                </div>
            }
            {temperatureLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>Temperature Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>#</th>
                                            <th rowSpan={2}>PoP Name</th>
                                            <th rowSpan={2}>Arduino Name</th>
                                            <th colSpan={4}>Temperature</th>
                                            <th rowSpan={2}>Date Time</th>
                                        </tr>
                                        <tr>
                                            <th>Aisle_Cold_1</th>
                                            <th>Aisle_Cold_2</th>
                                            <th>Aisle_Hot_1</th>
                                            <th>Aisle_Hot_2</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {temperatureLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.aisle_cold_1_temperature}</td>
                                                <td>{row.aisle_cold_2_temperature}</td>
                                                <td>{row.aisle_hot_1_temperature}</td>
                                                <td>{row.aisle_hot_2_temperature}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}


                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="PoP Data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>

                    </div>


                </div>
            }

            {phaseLog.length !== 0 &&
                <div className='container-fluid' >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className='text-center p-2' style={{ background: '#96abc1' }}>
                                <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>Phase Logs</h4>
                            </div>
                        </div>

                        <div className='col-md-12 text-center'>
                            <div class="mb-3 w-25 float-right">
                                <input
                                    type="text"
                                    class="form-control"
                                    name='search'
                                    placeholder="Search..."
                                    onChange={searchHandle}
                                    value={searchValue}
                                />
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div class="table-responsive" style={{ height: '50vh', overflowY: 'auto' }}>
                                <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>PoP Name</th>
                                            <th>Arduino Name</th>
                                            <th>Phase ID</th>
                                            <th>Phase Status</th>
                                            <th>Date Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {phaseLog.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>{row.pop_name}</td>
                                                <td>{row.arduino_name}</td>
                                                <td>{row.phase_id}</td>
                                                <td>{row.phase_status}</td>
                                                <td>{moment(row.created_at).format('lll')}</td>
                                            </tr>))}


                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-3 float-right'>
                                <DownloadTableExcel
                                    filename="Phase_data"
                                    sheet="Data"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                                </DownloadTableExcel>
                            </div>
                        </div>

                    </div>


                </div>
            }
        </>
    )
}
