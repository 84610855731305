const totalPoP = [
    "#",
    "PoP Name",
    "Temperature (°C)",
    "Humidity (%)",
    "Electricity",
    "Generator",
    "UPS-1",
    "UPS-2",
    "UPS-3",
    "AC-1",
    "AC-2",
    "AC-3",
    "Status"
];

const PhaseList = [
    "#",
    "PoP Name",
    "Arduino Name",
    "Phase ID",
    "Phase Corrector Capacity",
    "Action",
];

const electricityAlarm = [
    "#",
    "PoP Name",
    "Electricity",
    "Alarm Raised",
    "Duration",
    "Generator"
];

const upsAlarm = [
    "#",
    "PoP Name",
    "Status",
    "Alarm Raised",
    "Duration",
];

const generatorRunning = [
    "#",
    "PoP Name",
    "Generator",
    "Start Time",
    "Duration",
];

const generatorNotRunning = [
    "#",
    "PoP Name",
    "Electricity",
    "Generator",
    "Alarm Raised",
    "Duration",
];
const temperatureAlarm = [
    "#",
    "PoP Name",
    "Temperature",
    "Alarm Raised",
    "Duration",
];
const PoPList = [
    "#",
    "PoP Name",
    "Temperature Threshold",
    "Humidity Threshold",
    "Action",
];
const ArduinoList = [
    "#",
    "PoP Name",
    "Device ID",
    "Device Name",
    "Action",
];
const ACList = [
    "#",
    "PoP Name",
    "Arduino Name",
    "AC ID",
    "AC Capacity",
    "Action",
];
const UpsList = [
    "#",
    "PoP Name",
    "Arduino Name",
    "UPS ID",
    "UPS Capacity",
    "Action",
];
const GeneratorList = [
    "#",
    "PoP Name",
    "Arduino Name",
    "Generator ID",
    "Generator Capacity",
    "Action",
];


export { totalPoP, electricityAlarm, upsAlarm, generatorRunning, generatorNotRunning, temperatureAlarm, PoPList, ArduinoList, ACList, UpsList, GeneratorList, PhaseList }
