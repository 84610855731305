import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export const PoPEdit = ({ popid, aisleEditID, modalClose, tableRefresh }) => {
    const navigate = useNavigate();

    const { pop_id } = useParams()

    const [pop_name, setPopname] = useState("");
    const [Cold_Aisle_1_Temperature_Threshold, setCold_Aisle_1_Temperature_Threshold] = useState("");
    const [Cold_Aisle_2_Temperature_Threshold, setCold_Aisle_2_Temperature_Threshold] = useState("");
    const [Hot_Aisle_1_Temperature_Threshold, setHot_Aisle_1_Temperature_Threshold] = useState("");
    const [Hot_Aisle_2_Temperature_Threshold, setHot_Aisle_2_Temperature_Threshold] = useState("");

    const [Cold_Aisle_1_Humidity_Threshold, setCold_Aisle_1_Humidity_Threshold] = useState("");
    const [Cold_Aisle_2_Humidity_Threshold, setCold_Aisle_2_Humidity_Threshold] = useState("");
    const [Hot_Aisle_1_Humidity_Threshold, setHot_Aisle_1_Humidity_Threshold] = useState("");
    const [Hot_Aisle_2_Humidity_Threshold, setHot_Aisle_2_Humidity_Threshold] = useState("");


    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        fetchProduct();
    }, [])

    const fetchProduct = async () => {
        await axios.post(`https://pab.race.net.bd/api/popedit?pop_id=${popid}`).then(({ data }) => {
            const {
                pop_id,
                pop_name,
                cold_aisle_1_temperature_threshold,
                cold_aisle_2_temperature_threshold,
                hot_aisle_1_temperature_threshold,
                hot_aisle_2_temperature_threshold,
                cold_aisle_1_humidity_threshold,
                cold_aisle_2_humidity_threshold,
                hot_aisle_1_humidity_threshold,
                hot_aisle_2_humidity_threshold,
            } = data[0]
            setPopname(pop_name);
            setCold_Aisle_1_Temperature_Threshold(cold_aisle_1_temperature_threshold);
            setCold_Aisle_2_Temperature_Threshold(cold_aisle_2_temperature_threshold);
            setHot_Aisle_1_Temperature_Threshold(hot_aisle_1_temperature_threshold);
            setHot_Aisle_2_Temperature_Threshold(hot_aisle_2_temperature_threshold);

            setCold_Aisle_1_Humidity_Threshold(cold_aisle_1_humidity_threshold);
            setCold_Aisle_2_Humidity_Threshold(cold_aisle_2_humidity_threshold);
            setHot_Aisle_1_Humidity_Threshold(hot_aisle_1_humidity_threshold);
            setHot_Aisle_2_Humidity_Threshold(hot_aisle_2_humidity_threshold);

        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    const updatePop = async (e) => {
        e.preventDefault();
        const formData = new FormData()
        formData.append('pop_name', pop_name);
        formData.append('cold_aisle_1_temperature_threshold', Cold_Aisle_1_Temperature_Threshold);
        formData.append('cold_aisle_2_temperature_threshold', Cold_Aisle_2_Temperature_Threshold);
        formData.append('hot_aisle_1_temperature_threshold', Hot_Aisle_1_Temperature_Threshold);
        formData.append('hot_aisle_2_temperature_threshold', Hot_Aisle_2_Temperature_Threshold);

        formData.append('cold_aisle_1_humidity_threshold', Cold_Aisle_1_Humidity_Threshold);
        formData.append('cold_aisle_2_humidity_threshold', Cold_Aisle_2_Humidity_Threshold);
        formData.append('hot_aisle_1_humidity_threshold', Hot_Aisle_1_Humidity_Threshold);
        formData.append('hot_aisle_2_humidity_threshold', Hot_Aisle_2_Humidity_Threshold);

        await axios.post(`https://pab.race.net.bd/api/popupdate?pop_id=${popid}`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: "Updated Successfull"
            })
            modalClose();
            tableRefresh();
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="form-wrapper">
            {
                Object.keys(validationError).length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger">
                                <ul className="mb-0">
                                    {
                                        Object.entries(validationError).map(([key, value]) => (
                                            <li key={key}>{value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }

            <Form onSubmit={updatePop}>
                <Row>
                    <Col>
                        <Form.Group controlId="pop_name">
                            <Form.Label>Pop Name</Form.Label>
                            <Form.Control type="text" value={pop_name} onChange={(event) => {
                                setPopname(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="cold_aisle_1_temperature_threshold">
                            <Form.Label>Cold Aisle-1 Temperature Threshold</Form.Label>
                            <Form.Control type="text" value={Cold_Aisle_1_Temperature_Threshold} onChange={(event) => {
                                setCold_Aisle_1_Temperature_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="cold_aisle_2_temperature_threshold">
                            <Form.Label>Cold Aisle-2 Temperature Threshold</Form.Label>
                            <Form.Control type="text" value={Cold_Aisle_2_Temperature_Threshold} onChange={(event) => {
                                setCold_Aisle_2_Temperature_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="hot_aisle_1_temperature_threshold">
                            <Form.Label>Hot Aisle-1 Temperature Threshold</Form.Label>
                            <Form.Control type="text" value={Hot_Aisle_1_Temperature_Threshold} onChange={(event) => {
                                setHot_Aisle_1_Temperature_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="hot_aisle_2_temperature_threshold">
                            <Form.Label>Hot Aisle-2 Temperature Threshold</Form.Label>
                            <Form.Control type="text" value={Hot_Aisle_2_Temperature_Threshold} onChange={(event) => {
                                setHot_Aisle_2_Temperature_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group controlId="cold_aisle_1_humidity_threshold">
                            <Form.Label>Cold Aisle-1 Humidity Threshold</Form.Label>
                            <Form.Control type="text" value={Cold_Aisle_1_Humidity_Threshold} onChange={(event) => {
                                setCold_Aisle_1_Humidity_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="cold_aisle_2_humidity_threshold">
                            <Form.Label>Cold Aisle-2 Humidity Threshold</Form.Label>
                            <Form.Control type="text" value={Cold_Aisle_2_Humidity_Threshold} onChange={(event) => {
                                setCold_Aisle_2_Humidity_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="hot_aisle_1_humidity_threshold">
                            <Form.Label>Hot Aisle-1 Humidity Threshold</Form.Label>
                            <Form.Control type="text" value={Hot_Aisle_1_Humidity_Threshold} onChange={(event) => {
                                setHot_Aisle_1_Humidity_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="hot_aisle_2_humidity_threshold">
                            <Form.Label>Hot Aisle-2 Humidity Threshold</Form.Label>
                            <Form.Control type="text" value={Hot_Aisle_2_Humidity_Threshold} onChange={(event) => {
                                setHot_Aisle_2_Humidity_Threshold(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" className="float-right" type="submit">
                    Update
                </Button>
            </Form>
        </div>
    )
}
