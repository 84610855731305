import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import axios from 'axios'
import { FaUserAlt, FaLock } from 'react-icons/fa';

// react-bootstrap
import { Form } from "react-bootstrap";

import { loginFromValidation } from '../../../schema/Validation';

const Login = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: loginFromValidation,
        onSubmit: (values, { resetForm }) => {
            setIsLoading(true);
            setErrorMessage('');
            axios.post(`https://pab.race.net.bd/api/userauthentication?username=${values.username}&password=${values.password}`)
                .then((response) => {
                    if (response.data.status == 200) {
                        setErrorMessage(response.data.error);
                        setIsLoading(false);
                    } else {
                        localStorage.setItem('user', response.data[0].username);
                        localStorage.setItem('role', response.data[0].role);
                        setIsLoading(false);
                        navigate("/dashboard");
                    }
                })
                .catch((error) => {
                    setErrorMessage('Server Error! ' + error.message);
                    setIsLoading(false);
                });
        },
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            <div className="container-fluid">
                <div className='row d-flex justify-content-center align-items-center' style={{ height: "100vh" }}>
                    <div className="col-md-4 bg-white rounded px-4 pb-4 border">
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img src='../../../../assets/logo/race.png' alt='Login logo' width="50%" height="auto" />
                        </div>
                        <span className='text-danger fw-bold d-flex justify-content-center'>{errorMessage !== '' ? errorMessage : ''}</span>
                        <div className="input-group flex-nowrap my-3">
                            <span className="input-group-text" id="addon-wrapping"><FaUserAlt /></span>
                            <input type="text"
                                   id="username"
                                   className={`form-control ${formik.touched.username && formik.errors.username ? 'is-invalid' : ''}`}
                                   placeholder="Username"
                                   value={formik.values.username}
                                   onChange={formik.handleChange}
                            />
                        </div>
                        <div className="input-group flex-nowrap">
                            <span className="input-group-text" id="addon-wrapping"><FaLock /></span>
                            <input type="password"
                                   id="password"
                                   className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                                   placeholder="Password"
                                   value={formik.values.password}
                                   onChange={formik.handleChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-success text-white float-end mt-3">{isLoading ? 'Loading...' : 'Login'}</button>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default Login
