import React, { useState } from 'react';
import { Container, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PoPAdd from './pop/PoPAdd';
import { TableHeader, TableBody, UpsAlarmTable, CutomizeTable, totalPoP, PoPList, ArduinoList, ACList, UpsList, GeneratorList, PhaseList } from '../../components/table';
import { ArduinoAdd } from './arduino/ArduinoAdd';
import { AcAdd } from './ac/AcAdd';
import { UpsAdd } from './ups/UpsAdd';
import { GeneratorAdd } from './generator/GeneratorAdd';
import { PhaseAdd } from './phase/PhaseAdd';




export const Device = () => {
    const [searchValue, setSearchValue] = useState('');

    const [PoP, setPoP] = useState(false);
    const [PoPView, setPoPView] = useState(false);

    const [arduino, setArduino] = useState(false);
    const [arduinoView, setArduinoView] = useState(false);

    const [ac, setAC] = useState(false);
    const [acView, setACView] = useState(false);

    const [ups, setUPS] = useState(false);
    const [upsView, setUpsView] = useState(false);

    const [generator, setGenerator] = useState(false);
    const [generatorView, setGeneratorView] = useState(false);

    const [phase, setPhase] = useState(false);
    const [phaseView, setPhaseView] = useState(false);

    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }


    const popAddHandler = () => {
        setPoP(true);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);
    }
    const popViewHandler = () => {
        setPoPView(true);
        setPoP(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);
    }

    const arduinoAddHandler = () => {
        setArduino(true);
        setArduinoView(false);
        setPoP(false);
        setPoPView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);
    }
    const arduinoViewHandler = () => {
        setArduinoView(true);
        setArduino(false);
        setPoP(false);
        setPoPView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);
    }

    const acAddHandler = () => {
        setAC(true);
        setACView(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setUPS(false);
        setUpsView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);
    }
    const acViewHandler = () => {
        setACView(true);
        setAC(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setUPS(false);
        setUpsView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);
    }

    const upsAddHandler = () => {
        setUPS(true);
        setUpsView(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);

    }
    const upsViewHandler = () => {
        setUpsView(true);
        setUPS(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPhase(false);
        setPhaseView(false);

    }

    const generatorAddHandler = () => {
        setGenerator(true);
        setGeneratorView(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
        setPhase(false);
        setPhaseView(false);
    }
    const generatorViewHandler = () => {
        setGeneratorView(true);
        setGenerator(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
        setPhase(false);
        setPhaseView(false);
    }

    const phaseAddHandler = () => {
        setPhase(true);
        setPhaseView(false);
        setGenerator(false);
        setGeneratorView(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
    }
    const phaseViewHandler = () => {
        setPhaseView(true);
        setPhase(false);
        setGeneratorView(false);
        setGenerator(false);
        setPoP(false);
        setPoPView(false);
        setArduino(false);
        setArduinoView(false);
        setAC(false);
        setACView(false);
        setUPS(false);
        setUpsView(false);
    }


    return (
        <Container fluid>
            <Row className='d-flex justify-content-center'>
                <Col md={2}>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            PoP
                        </button>
                        <ul class="dropdown-menu w-100 text-center">
                            <li><a class="dropdown-item" role="button" onClick={popAddHandler}>Add</a></li>
                            <li><a class="dropdown-item" role="button" onClick={popViewHandler}>View</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Device
                        </button>
                        <ul class="dropdown-menu w-100 text-center">
                            <li><a class="dropdown-item" role="button" onClick={arduinoAddHandler}>Add</a></li>
                            <li><a class="dropdown-item" role="button" onClick={arduinoViewHandler}>View</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            AC
                        </button>
                        <ul class="dropdown-menu w-100 text-center">
                            <li><a class="dropdown-item" role="button" onClick={acAddHandler}>Add</a></li>
                            <li><a class="dropdown-item" role="button" onClick={acViewHandler}>View</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            UPS
                        </button>
                        <ul class="dropdown-menu w-100 text-center">
                            <li><a class="dropdown-item" role="button" onClick={upsAddHandler}>Add</a></li>
                            <li><a class="dropdown-item" role="button" onClick={upsViewHandler}>View</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Generator
                        </button>
                        <ul class="dropdown-menu w-100 text-center">
                            <li><a class="dropdown-item" role="button" onClick={generatorAddHandler}>Add</a></li>
                            <li><a class="dropdown-item" role="button" onClick={generatorViewHandler}>View</a></li>
                        </ul>
                    </div>
                </Col>
                <Col md={2}>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Phase
                        </button>
                        <ul class="dropdown-menu w-100 text-center">
                            <li><a class="dropdown-item" role="button" onClick={phaseAddHandler}>Add</a></li>
                            <li><a class="dropdown-item" role="button" onClick={phaseViewHandler}>View</a></li>
                        </ul>
                    </div>
                </Col>
            </Row>

            {/* PoP */}
            {PoP &&
                <PoPAdd />}

            {PoPView &&
                <Row className='mt-5'>
                    <Col md={12}>
                        <div class="mb-3 w-25 float-right">
                            <input
                                type="text"
                                class="form-control"
                                name='search'
                                placeholder="Search..."
                                onChange={searchHandle}
                                value={searchValue}
                            />
                        </div>
                    </Col>
                    <Col md={12}>
                        <CutomizeTable columns={PoPList ? PoPList : ''} search={searchValue ? searchValue : ''} apicall={PoPView ? PoPView : ''} />
                    </Col>
                </Row>
            }
            {/* End PoP */}

            {/* Arduino */}
            {arduino && <ArduinoAdd />}

            {arduinoView && <Row className='mt-5'>
                <Col md={12}>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <CutomizeTable columns={ArduinoList ? ArduinoList : ''} search={searchValue ? searchValue : ''} apicallarduino={arduinoView ? arduinoView : ''} />
                </Col>
            </Row>
            }
            {/* End Arduino */}

            {/* Ac */}
            {ac && <AcAdd />}

            {acView && <Row className='mt-5'>
                <Col md={12}>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <CutomizeTable columns={ACList ? ACList : ''} search={searchValue ? searchValue : ''} apicallac={acView ? acView : ''} />
                </Col>
            </Row>}

            {/* End Ac */}

            {/* UPS */}
            {ups && <UpsAdd />}
            {upsView && <Row className='mt-5'>
                <Col md={12}>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <CutomizeTable columns={UpsList ? UpsList : ''} search={searchValue ? searchValue : ''} apicallups={upsView ? upsView : ''} />
                </Col>
            </Row>}
            {/* End UPS */}

            {/* Generator */}
            {generator && <GeneratorAdd />}
            {generatorView && <Row className='mt-5'>
                <Col md={12}>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <CutomizeTable columns={GeneratorList ? GeneratorList : ''} search={searchValue ? searchValue : ''} apicallgenerator={generatorView ? generatorView : ''} />
                </Col>
            </Row>}
            {/* End Generator */}

            {/* Phase */}
            {phase && <PhaseAdd />}
            {phaseView && <Row className='mt-5'>
                <Col md={12}>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </Col>
                <Col md={12}>
                    <CutomizeTable columns={PhaseList ? PhaseList : ''} search={searchValue ? searchValue : ''} apicallphase={phaseView ? phaseView : ''} />
                </Col>
            </Row>}
            {/* End Phase */}

        </Container>


    )
}
