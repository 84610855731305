import React from 'react'
import Menu from './../menu/index';
import Footer from './Footer';
import Header from './Header';

export const MainContent = ({children}) => {
  return (
    <div class="wrapper">
    <Header/>
    <Menu/>
    <div className="content-wrapper">
      <section className="content py-3">
        {children}
      </section>
    </div>
    <Footer/>      
  </div>
  )
}
