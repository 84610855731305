import React, { useEffect, useState, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from 'react-icons/si';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const DeviceList = () => {
    const tableRef = useRef(null);
    const [deviceListData, setDeviceListData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }

    useEffect(() => {
        deviceList()
    }, [])

    const deviceList = async () => {
        await axios.get(`https://pab.race.net.bd/api/reportsdevicelist`).then(({ data }) => {
            setDeviceListData(data)
        })
    }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className='text-center p-2' style={{ background: '#96abc1' }}>
                        <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>Device List</h4>
                    </div>
                </div>

                <div className='col-md-12 text-center'>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </div>

                <div className='col-md-12'>
                    <div class="table-responsive" style={{ height: '60vh', overflowY: 'auto' }}>
                        <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>PoP Name</th>
                                    <th colSpan={4}>UPS</th>
                                    <th colSpan={2}>Generator</th>
                                    <th colSpan={4}>AC</th>
                                    <th rowSpan={2}>Phase Sequence</th>
                                </tr>
                                <tr>
                                    <th>UPS-1</th>
                                    <th>UPS-2</th>
                                    <th>UPS-3</th>
                                    <th>UPS-4</th>

                                    <th>G-1</th>
                                    <th>G-2</th>

                                    <th>AC-1</th>
                                    <th>AC-2</th>
                                    <th>AC-3</th>
                                    <th>AC-4</th>
                                </tr>
                            </thead>
                            <tbody>

                                {deviceListData && (
                                    deviceListData.filter((val) => {
                                        if (searchValue == '') {
                                            return val;
                                        } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                            return val;
                                        }
                                    }).map((row, key) => (
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            <td>{row.pop_name}</td>
                                            <td>{row.ups_1_description === null ? '--' : row.ups_1_description}</td>
                                            <td>{row.ups_2_description === null ? '--' : row.ups_2_description}</td>
                                            <td>{row.ups_3_description === null ? '--' : row.ups_3_description}</td>
                                            <td>{row.ups_4_description === null ? '--' : row.ups_4_description}</td>
                                            <td>{row.generator_1_description === null ? '--' : `${row.generator_1_description}`}</td>
                                            <td>{row.generator_2_description === null ? '--' : `${row.generator_2_description}`}</td>
                                            <td>{row.ac_1_description === null ? '--' : `${row.ac_1_description}`}</td>
                                            <td>{row.ac_2_description === null ? '--' : `${row.ac_2_description}`}</td>
                                            <td>{row.ac_3_description === null ? '--' : `${row.ac_3_description}`}</td>
                                            <td>{row.ac_4_description === null ? '--' : `${row.ac_4_description}`}</td>
                                            <td>{row.phase_description === null ? '--' : `${row.phase_description}`}</td>
                                        </tr>
                                    ))


                                )
                                }
                            </tbody>
                        </table>

                        <div className='mt-3 float-right'>
                            <DownloadTableExcel
                                filename="Device_List"
                                sheet="Data"
                                currentTableRef={tableRef.current}
                            >
                                <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                            </DownloadTableExcel>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
