import React, { useEffect, useState } from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import { FaPenAlt } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';


import useFetch from '../../../customHooks/useFetch';
import { PoPEdit } from '../../Pages/device/pop/PoPEdit';
import { ArduinoEdit } from '../../Pages/device/arduino/ArduinoEdit';
import { AcEdit } from './../../Pages/device/ac/AcEdit';
import { UpsEdit } from './../../Pages/device/ups/UpsEdit';
import { GeneratorEdit } from './../../Pages/device/generator/GeneratorEdit';
import { PhaseEdit } from '../../Pages/device/phase/PhaseEdit';


export const TableBody = ({ search, apicall, apicallarduino, apicallac, apicallups, apicallgenerator, apicallphase }) => {
  const [show, setShow] = useState(false);
  const [popEditID, setPopEditID] = useState(null);
  const [aisleEditID, setAisleEditID] = useState(null);
  const [arduinoEditID, setaArduinoEditID] = useState(null);
  const [acEditID, setACEditID] = useState(null);
  const [upsEditID, setUPSEditID] = useState(null);
  const [generatorEditID, setGeneratorEditID] = useState(null);
  const [phaseEditID, setPhaseEditID] = useState(null);

  const handleClose = () => setShow(false);

  const [PoPList, setPoPList] = useState(null);


  useEffect(() => {
    PoPListFetch();
  }, [apicall])

  const PoPListFetch = async () => {
    await axios.get(`https://pab.race.net.bd/api/popwithtemparaturehumidityviewdata`)
      .then(({ data }) => {
        setPoPList(data)
      })
      .catch(({ message }) => {
        Swal.fire({
          text: message,
          icon: "error"
        })
      })
  }

  const DeletePoPList = async (pop_id) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`https://pab.race.net.bd/api/popdelete/${pop_id}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      PoPListFetch();
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  const EditPoPList = async (popid) => {
    setShow(true);
    setPopEditID(popid);
  }

  // for Arduino
  const [arduinoList, setArduinoList] = useState([]);

  useEffect(() => {
    fetchArduinoList();
  }, [apicallarduino])

  const fetchArduinoList = async () => {
    await axios.get(`https://pab.race.net.bd/api/arduinofetch`).then(({ data }) => {
      setArduinoList(data)
    })
  }

  const DeleteArduinoList = async (arduino_id) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`https://pab.race.net.bd/api/arduinodelete/${arduino_id}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: "Deleted Successfully",
      })
      fetchArduinoList()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  const EditArduinoList = async (arduinoid) => {
    setShow(true);
    setaArduinoEditID(arduinoid)
  }

  // for Ac
  const [acList, setACList] = useState([]);
  useEffect(() => {
    fetchACList()
  }, [])

  const fetchACList = async () => {
    await axios.get(`https://pab.race.net.bd/api/acfetch`).then(({ data }) => {
      setACList(data);
    })
  }

  const DeleteACList = async (acid) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`https://pab.race.net.bd/api/acdelete/${acid}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      fetchACList()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }


  const EditACList = async (acid) => {
    setShow(true);
    setACEditID(acid);
  }

  // for Ups
  const [upsList, setUPSList] = useState([]);


  useEffect(() => {
    fetchUPSList()
  }, [apicallups])

  const fetchUPSList = async () => {
    await axios.get(`https://pab.race.net.bd/api/upsfetch`).then(({ data }) => {
      setUPSList(data)
    })
  }

  const DeleteUPSList = async (upsid) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`https://pab.race.net.bd/api/upsdelete/${upsid}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      fetchUPSList()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  const EditUPSList = async (upsid) => {
    setShow(true);
    setUPSEditID(upsid);
  }
  // for Generator
  const [generatorList, setGeneratorList] = useState([]);

  useEffect(() => {
    fetchGeneratorList()
  }, [apicallgenerator])

  const fetchGeneratorList = async () => {
    await axios.get(`https://pab.race.net.bd/api/generatorfetch`).then(({ data }) => {
      setGeneratorList(data)
    })
  }

  const DeleteGeneratorList = async (generatorid) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`https://pab.race.net.bd/api/generatordelete/${generatorid}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.message
      })
      fetchGeneratorList()
    }).catch(({ response: { data } }) => {
      Swal.fire({
        text: data.message,
        icon: "error"
      })
    })
  }

  const EditGeneratorList = async (generatorid) => {
    setShow(true);
    setGeneratorEditID(generatorid);
  }

  // for Phase
  const [phaseList, setPhaseList] = useState([]);

  useEffect(() => {
    fetchPhaseList()
  }, [apicallphase])

  const fetchPhaseList = async () => {
    await axios.get(`https://pab.race.net.bd/api/phasefetch`).then(({ data }) => {
      setPhaseList(data)
    })
  }

  const DeletePhaseList = async (id) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      return result.isConfirmed
    });

    if (!isConfirm) {
      return;
    }

    await axios.delete(`https://pab.race.net.bd/api/phasedelete/${id}`).then(({ data }) => {
      Swal.fire({
        icon: "success",
        text: data.massage
      })

      fetchPhaseList()
    }).catch((error) => {
      Swal.fire({
        text: error.message,
        icon: "error"
      })
    })
  }

  const EditPhaseList = async (id) => {
    setShow(true);
    setPhaseEditID(id);

  }




  return (
    <tbody>

      {apicall && PoPList && (
        PoPList.filter((val) => {
          if (search == '') {
            return val;
          } else if (val.pop_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return val;
          }
        }).map((row, key) => (

          <tr key={key}>
            <td>{key + 1}</td>
            <td>{row.pop_name}</td>
            <td>{row.cold_aisle_1_temperature_threshold}</td>
            <td>{row.cold_aisle_2_temperature_threshold}</td>
            <td>{row.hot_aisle_1_temperature_threshold}</td>
            <td>{row.hot_aisle_2_temperature_threshold}</td>

            <td>{row.cold_aisle_1_humidity_threshold}</td>
            <td>{row.cold_aisle_2_humidity_threshold}</td>
            <td>{row.hot_aisle_1_humidity_threshold}</td>
            <td>{row.hot_aisle_2_humidity_threshold}</td>

            <td>
              <button className='table-edit-btn' onClick={() => EditPoPList(row.pop_id)}>
                <FaPenAlt />
              </button>

              <button className='table-delete-btn' onClick={() => DeletePoPList(row.pop_id)}>
                <AiFillDelete />
              </button>
              {/* </OverlayTrigger> */}
            </td>
          </tr>
        ))
      )}

      {apicallarduino && arduinoList && (
        arduinoList.filter((val) => {
          if (search == '') {
            return val;
          } else if (val.pop_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return val;
          }
        }).map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{row.pop_name}</td>
            <td>{row.arduino_id}</td>
            <td>{row.arduino_name}</td>
            <td>
              <button className='table-edit-btn' onClick={() => EditArduinoList(row.arduino_id)}>
                <FaPenAlt />
              </button>

              <button className='table-delete-btn' onClick={() => DeleteArduinoList(row.arduino_id)}>
                <AiFillDelete />
              </button>
            </td>
          </tr>
        ))
      )}


      {apicallac && acList && (
        acList.filter((val) => {
          if (search == '') {
            return val;
          } else if (val.pop_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return val;
          }
        }).map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{row.pop_name}</td>
            <td>{row.arduino_name}</td>
            <td>{row.ac_id}</td>
            <td>{row.ac_description}</td>
            <td>
              <button className='table-edit-btn' onClick={() => EditACList(row.acid)}>
                <FaPenAlt />
              </button>

              <button className='table-delete-btn' onClick={() => DeleteACList(row.acid)}>
                <AiFillDelete />
              </button>
            </td>
          </tr>
        ))
      )}

      {apicallups && upsList && (
        upsList.filter((val) => {
          if (search == '') {
            return val;
          } else if (val.pop_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return val;
          }
        }).map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{row.pop_name}</td>
            <td>{row.arduino_name}</td>
            <td>{row.ups_id}</td>
            <td>{row.ups_description}</td>
            <td>
              <button className='table-edit-btn' onClick={() => EditUPSList(row.upsid)}>
                <FaPenAlt />
              </button>

              <button className='table-delete-btn' onClick={() => DeleteUPSList(row.upsid)}>
                <AiFillDelete />
              </button>
            </td>
          </tr>
        ))
      )}

      {apicallgenerator && generatorList && (
        generatorList.filter((val) => {
          if (search == '') {
            return val;
          } else if (val.pop_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return val;
          }
        }).map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{row.pop_name}</td>
            <td>{row.arduino_name}</td>
            <td>{row.generator_id}</td>
            <td>{row.generator_description}</td>
            <td>
              <button className='table-edit-btn' onClick={() => EditGeneratorList(row.generatorid)}>
                <FaPenAlt />
              </button>

              <button className='table-delete-btn' onClick={() => DeleteGeneratorList(row.generatorid)}>
                <AiFillDelete />
              </button>
            </td>
          </tr>
        ))
      )}

      {apicallphase && phaseList && (
        phaseList.filter((val) => {
          if (search == '') {
            return val;
          } else if (val.pop_name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            return val;
          }
        }).map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{row.pop_name}</td>
            <td>{row.arduino_name}</td>
            <td>{row.phase_id}</td>
            <td>{row.phase_description}</td>
            <td>
              <button className='table-edit-btn' onClick={() => EditPhaseList(row.id)}>
                <FaPenAlt />
              </button>

              <button className='table-delete-btn' onClick={() => DeletePhaseList(row.id)}>
                <AiFillDelete />
              </button>
            </td>
          </tr>
        ))
      )}


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {popEditID && <PoPEdit popid={popEditID} aisleEditID={aisleEditID} modalClose={handleClose} tableRefresh={PoPListFetch} />}
          {arduinoEditID && <ArduinoEdit arduinoID={arduinoEditID} modalClose={handleClose} tableRefresh={fetchArduinoList} />}
          {acEditID && <AcEdit acid={acEditID} modalClose={handleClose} tableRefresh={fetchACList} />}
          {upsEditID && <UpsEdit upsid={upsEditID} modalClose={handleClose} tableRefresh={fetchUPSList} />}
          {generatorEditID && <GeneratorEdit generatorid={generatorEditID} modalClose={handleClose} tableRefresh={fetchGeneratorList} />}
          {phaseEditID && <PhaseEdit phaseid={phaseEditID} modalClose={handleClose} tableRefresh={fetchPhaseList} />}
        </Modal.Body>
      </Modal>


      {/* {pop.filter((val)=>{
            if (search == '') {
              return val;
            } else if (val.popname.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
              return val;
            }
        }).map((item,key)=>(
          <tr>
            <td>{item.id}</td>
            <td>{item.popname}</td>
            <td>{item.temparature}</td>
            <td>{item.humidity}</td>
            <td>{item.electricity}</td>
            <td>{item.generator}</td>
            <td>{item.ups_1}</td>
            <td>{item.ups_2}</td>
            <td>{item.ups_3}</td>
            <td>{item.ac_1}</td>
            <td>{item.ac_2}</td>
            <td>{item.ac_3}</td>
            <td>{item.status}</td>
          </tr>
        ))} */}

    </tbody>

  )
}
