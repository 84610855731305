import React from 'react';

export const Loader = () => {
  return (
    <div style={{
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      // background: 'cornflowerblue',
      background: 'black',
      zIndex: '15',
      borderRadius: '3px',
      top: '0',
      left: '0'
    }}>
      {/* <button className="btn btn-primary" type="button" disabled>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button> */}
      <div class="spinner-border text-white" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}
