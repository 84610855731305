import React, { useRef, useState, useEffect } from 'react';
import axios from "axios";
import { useFormik, resetForm } from 'formik';
import Swal from 'sweetalert2';
import { FaPenAlt } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from 'react-icons/si';
import Modal from 'react-bootstrap/Modal';
import '../../components/table/CustomizeTable.css';
import { EditUser } from './EditUser';

export const UserList = () => {
    const tableRef = useRef(null);

    const columns = [
        '#',
        "Full Name",
        "Username",
        "Email",
        "Phone",
        "Role",
        "Status",
        "Allow Email Notification",
        "Action"
    ];
    const [searchValue, setSearchValue] = useState('');
    const [userList, setUserList] = useState('');
    const [user_id, setUserId] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }

    const userListFetch = async () => {
        await axios.get(`https://pab.race.net.bd/api/userlistfetch`)
            .then(({ data }) => {
                setUserList(data);
            })
            .catch(({ message }) => {
                Swal.fire({
                    text: message,
                    icon: "error"
                })
            })
    }

    useEffect(() => {
        userListFetch();
    }, [])

    const EditUserList = (user_id) => {
        setShow(true);
        setUserId(user_id);
    }

    const DeleteUserList = async (user_id) => {
        const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            return result.isConfirmed
        });

        if (!isConfirm) {
            return;
        }

        await axios.delete(`https://pab.race.net.bd/api/userdelete/${user_id}`).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            userListFetch();
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className='text-center p-2' style={{ background: '#96abc1' }}>
                        <h4 className='m-0 fw-bolder' style={{ fontSize: '18px' }}>User List</h4>
                    </div>
                </div>
                <div className='col-md-12 text-center'>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <div class="table-responsive">
                        <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                            <thead>
                                <tr>
                                    {columns && columns.map((column, key) => (
                                        <th key={key}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {userList && userList.filter((val) => {
                                    if (searchValue == '') {
                                        return val;
                                    } else if (val.username.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                        return val;
                                    }
                                }).map((item, key) => (
                                    <tr key={key}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.username}</td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.role}</td>
                                        <td>{item.status}</td>
                                        <td>{item.is_email_notification}</td>
                                        <td>
                                            <button className='table-edit-btn' onClick={() => EditUserList(item.id)}>
                                                <FaPenAlt />
                                            </button>

                                            <button className='table-delete-btn' onClick={() => DeleteUserList(item.id)}>
                                                <AiFillDelete />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className='mt-3 float-right'>
                            <DownloadTableExcel
                                filename="PoP Data"
                                sheet="Data"
                                currentTableRef={tableRef.current}
                            >
                                <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                            </DownloadTableExcel>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {user_id && <EditUser userid={user_id} modalClose={handleClose} tableRefresh={userListFetch} />}
                </Modal.Body>
            </Modal>
        </div>
    )
}
