import React, { Component, useEffect,useState } from 'react'
import { NavLink, Link } from 'react-router-dom';
import axios from "axios";
import { FiSettings } from 'react-icons/fi';
// import { GoDashboard } from 'react-icons/go';
import { VscDashboard } from "react-icons/vsc";
import { AiOutlineDashboard } from 'react-icons/ai';
import { TbReportSearch } from 'react-icons/tb';




function Menu() {
  const role = localStorage.getItem('role');
  const [alarmCount, setAlarmCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    PoPAlarmCount();
    const intervalCall = setInterval(() => {
      PoPAlarmCount();
    }, 50000);
    return () => {
      clearInterval(intervalCall);
    };

  }, []);

  const PoPAlarmCount = async () => {
    setIsLoading(true);
    await axios.get("https://pab.race.net.bd/api/totalalarmpopfetch")
      .then(({ data }) => {
        setAlarmCount(data[0].pop_alarm);
        setIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboard" className="brand-link">
          <img src="../../../assets/logo/race.png" alt="Logo" width="70%" height="50%" />
          {/* <span className="brand-text font-weight-light">Race Online Ltd</span> */}
        </Link>




        <div className="sidebar">

          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img src="../../../assets/profile/profile.png" className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
              <a role="button" className="nav-link d-block">{localStorage.getItem('user')}</a>
            </div>
          </div>

          <nav className="mt-2">

            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
              <li className="nav-item">
                <Link to="/dashboard" className="nav-link">
                  <AiOutlineDashboard className="nav-icon" />
                  <p>
                    Main Dashboard
                    <span className='badge text-bg-danger ms-3' style={{ fontSize: '12px' }}>{isLoading ? 'Loading..' : alarmCount}</span>
                  </p>
                </Link>
              </li>


              <li className="nav-item">
                <Link to="/popdashboard/1" className="nav-link">
                  <VscDashboard className="nav-icon" />
                  <p>
                    PoP Dashboard
                  </p>
                </Link>
              </li>

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  {/* <i className="nav-icon fas fa-copy" /> */}
                  <TbReportSearch className="nav-icon" />
                  <p>
                    Reports
                    <i className="fas fa-angle-left right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavLink to="/reports/devicelist" className="nav-link"> <i className="far fa-circle nav-icon" />
                      <p>Device List</p></NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/reports/log" className="nav-link"> <i className="far fa-circle nav-icon" />
                      <p>Log</p></NavLink>
                  </li>
                </ul>
              </li>

              {role && role === 'Admin' || role === 'Guest' ? ''
                : <li className="nav-item has-treeview">
                  <a className="nav-link">
                    <FiSettings className="nav-icon" />
                    <p>
                      Settings
                      <i className="fas fa-angle-left right" />

                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <NavLink to="/device" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Device</p>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/register" className="nav-link"> <i className="far fa-circle nav-icon" />
                        <p>Sign Up</p></NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/userlist" className="nav-link"> <i className="far fa-circle nav-icon" />
                        <p>User List</p></NavLink>
                    </li>
                  </ul>
                </li>}

            </ul>
          </nav>

        </div>



      </aside>
    </div>


  )
}

export default Menu;
