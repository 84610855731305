import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useParams, Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';

export function ArduinoEdit({ arduinoID, modalClose, tableRefresh }) {
    const navigate = useNavigate();
    const { arduino_id } = useParams()
    const [pop_name, setPop_name] = useState("")
    const [arduino_name, setArduino_name] = useState("")
    const [arduino_status, setArduino_status] = useState("")
    const [validationError, setValidationError] = useState({})

    useEffect(() => {
        arduinoEdit()
    }, [])

    const arduinoEdit = async () => {
        await axios.get(`https://pab.race.net.bd/api/arduinoedit/${arduinoID}`).then(({ data }) => {
            const { pop_name, arduino_name, arduino_status } = data.arduino
            setPop_name(pop_name)
            setArduino_name(arduino_name)
            setArduino_status(arduino_status)
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    const updatePop = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('pop_name', pop_name);
        formData.append('arduino_name', arduino_name);
        formData.append('arduino_status', arduino_status);

        await axios.post(`https://pab.race.net.bd/api/arduinoupdate/${arduinoID}`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            modalClose();
            tableRefresh();
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="form-wrapper">
            {
                Object.keys(validationError).length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger">
                                <ul className="mb-0">
                                    {
                                        Object.entries(validationError).map(([key, value]) => (
                                            <li key={key}>{value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
            <Form onSubmit={updatePop}>
                <Row>
                    <Col>
                        <Form.Group controlId="pop_name">
                            <Form.Label>PoP Name</Form.Label>
                            <Form.Control disabled type="text" value={pop_name} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="arduino_name">
                            <Form.Label>Arduino Name</Form.Label>
                            <Form.Control type="text" value={arduino_name} onChange={(event) => {
                                setArduino_name(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" className="float-right" type="submit">
                    Update
                </Button>
            </Form>
        </div>
    )
}

export default ArduinoEdit;