import React, { useEffect, useState, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from 'react-icons/si';
import axios from 'axios';
import { Link } from 'react-router-dom';


export function TemperatureDetailsTable() {

    const tableRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const role = sessionStorage.getItem('role');

    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const fetchProducts = async () => {
        await axios.get(`https://pab.race.net.bd/api/totaltemperaturealarmdetails`).then(({ data }) => {
            setProducts(data)
        })
    }
    const convertDayHoursMinutes = (minutes) => {
        // Calculate the number of days
        const days = Math.floor(minutes / 1440);

        // Calculate the number of hours
        const hours = Math.floor((minutes % 1440) / 60);

        // Calculate the number of minutes
        const remainingMinutes = minutes % 60;

        return `${days} Days ${hours} Hr ${remainingMinutes} Mins`;
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className='text-center p-2' style={{ background: '#96abc1' }}>
                        <h4 className='m-0 fw-bolder' style={{ fontSize: '18px', color: 'white' }}>Temperature Alarm</h4>

                    </div>
                </div>

                <div className='col-md-12 text-center'>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </div>

                <div className='col-md-12'>
                    <div class="table-responsive" style={{ height: '60vh', overflowY: 'auto' }}>
                        <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>PoP Name</th>
                                    <th colSpan={4}>Temperature (°C)</th>
                                    <th rowSpan={2}>Alarm Raised</th>
                                    <th rowSpan={2}>Duration</th>
                                </tr>
                                <tr>
                                    <th>Aisle_Cold_1</th>
                                    <th>Aisle_Cold_2</th>
                                    <th>Aisle_Hot_1</th>
                                    <th>Aisle_Hot_2</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    products && (
                                        products.map((rowss, key) => (
                                            rowss.map((rows) => (
                                                rows.filter((val) => {
                                                    if (searchValue == '') {
                                                        return val;
                                                    } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                        return val;
                                                    }
                                                }).map((row) => (
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        {role && role === 'Guest' ? `${row.pop_name}` :
                                                            <td><Link className="nav-link" to={`/popdashboard/${row.pop_id}`}>{row.pop_name}</Link></td>}
                                                        {row.aisle_cold_1_temperature >= row.cold_aisle_1_temperature_threshold ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_cold_1_temperature}</td> : <td>{row.aisle_cold_1_temperature}</td>}
                                                        {row.aisle_cold_2_temperature >= row.cold_aisle_2_temperature_threshold ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_cold_2_temperature}</td> : <td>{row.aisle_cold_2_temperature}</td>}
                                                        {row.aisle_hot_1_temperature >= row.hot_aisle_1_temperature_threshold ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_hot_1_temperature}</td> : <td>{row.aisle_hot_1_temperature}</td>}
                                                        {row.aisle_hot_2_temperature >= row.hot_aisle_2_temperature_threshold ? <td style={{ background: 'red', color: 'white', fontWeight: 'bolder' }}>{row.aisle_hot_2_temperature}</td> : <td>{row.aisle_hot_2_temperature}</td>}

                                                        <td>{row.Alarm_Raised}</td>
                                                        <td>{convertDayHoursMinutes(row.Duration)}</td>
                                                    </tr>
                                                ))
                                            ))
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-3 float-right'>
                        <DownloadTableExcel
                            filename="PoP Data"
                            sheet="Data"
                            currentTableRef={tableRef.current}
                        >
                            <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                        </DownloadTableExcel>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TemperatureDetailsTable;