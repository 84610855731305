import { React } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { MainContent } from './../modules/layouts/MainContent';

export const PrivateRoutes = () => {
    const isLoggedIn = localStorage.getItem('user');
  return (
    <>
        {isLoggedIn? <MainContent> <Outlet /> </MainContent> : <Navigate to="/" replace />}
      
    </>
  )
}
