import React, { useEffect, useState, useRef } from 'react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { SiMicrosoftexcel } from 'react-icons/si';
import axios from 'axios';
import { Link } from 'react-router-dom';


export const DeviceResponseAlarmTable = () => {

    const tableRef = useRef(null);
    const [products, setProducts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const role = sessionStorage.getItem('role');

    const searchHandle = (e) => {
        setSearchValue(e.target.value);
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    const fetchProducts = async () => {
        await axios.get(`https://pab.race.net.bd/api/deviceresponsalarmdetails`).then(({ data }) => {
            setProducts(data)
        })
    }

    const convertDayHoursMinutes = (minutes) => {
        // Calculate the number of days
        const days = Math.floor(minutes / 1440);

        // Calculate the number of hours
        const hours = Math.floor((minutes % 1440) / 60);

        // Calculate the number of minutes
        const remainingMinutes = minutes % 60;

        return `${days} Days ${hours} Hr ${remainingMinutes} Mins`;
    }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 mb-3'>
                    <div className='text-center p-2' style={{ background: '#96abc1' }}>
                        <h4 className='m-0 fw-bolder' style={{ fontSize: '18px', color: 'white' }}>Device Response Alarm</h4>
                    </div>
                </div>

                <div className='col-md-12 text-center'>
                    <div class="mb-3 w-25 float-right">
                        <input
                            type="text"
                            class="form-control"
                            name='search'
                            placeholder="Search..."
                            onChange={searchHandle}
                            value={searchValue}
                        />
                    </div>
                </div>

                <div className='col-md-12'>
                    <div class="table-responsive" style={{ height: '60vh', overflowY: 'auto' }}>
                        <table className="table-bordered table-hover" style={{ width: '100%' }} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>PoP Name</th>
                                    <th>Device Name</th>
                                    <th>Last Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    products && (
                                        products.filter((val) => {
                                            if (searchValue == '') {
                                                return val;
                                            } else if (val.pop_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                                                return val;
                                            }
                                        }).map((row, key) => (
                                            <tr key={key}>
                                                <td>{key + 1}</td>
                                                {role && role === 'Guest' ? `${row.pop_name}` :
                                                    <td><Link className="nav-link" to={`/popdashboard/${row.pop_id}`}>{row.pop_name}</Link></td>}
                                                <td>{row.arduino_name}</td>
                                                <td>{row.updated_at}</td>
                                            </tr>
                                        ))


                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-3 float-right'>
                        <DownloadTableExcel
                            filename="PoP Data"
                            sheet="Data"
                            currentTableRef={tableRef.current}
                        >
                            <button className='btn btn-success'><SiMicrosoftexcel style={{ fontSize: '18px', marginRight: '6px' }} /> Export</button>
                        </DownloadTableExcel>
                    </div>
                </div>

            </div>
        </div>
    )
}
