import React, { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { FaNetworkWired, FaCheckCircle, FaUps } from 'react-icons/fa';
import { SiAmp } from 'react-icons/si';
import { RiTempHotLine, RiRestartFill } from 'react-icons/ri';
import { GiPowerGenerator } from 'react-icons/gi';
import { MdElectricalServices } from 'react-icons/md';
import { BsFillBellFill, BsFillBellSlashFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import axios from "axios";
import { Loader } from '../components/loader/Loader';




export const Dashboard = () => {

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    trees.Treeview('init');
  }, []);

  const [lastRefresh, setRefreshTime] = useState(new Date());
  const [alarm, setAlarm] = useState(true);
  const [muted, setMuted] = useState(true);
  const [popAlarmMuted, setPopAlarmMuted] = useState(true);
  const [electricityAlarmMuted, setElectricityAlarmMuted] = useState(true);
  const [upsAlarmMuted, setUpsAlarmMuted] = useState(true);
  const [generatorAlarmMuted, setGeneratorAlarmMuted] = useState(true);
  const [phaseAlarmMuted, setPhaseAlarmMuted] = useState(true);

  const [popCount, setPopCount] = useState([]);
  const [popCountIsLoading, setPopCountIsLoading] = useState(true);

  const [phaseCount, setPhaseCount] = useState([]);
  const [phaseCountIsLoading, setPhaseCountIsLoading] = useState(true);

  const [popAlarmCount, setPopAlarmCount] = useState([]);
  const [popAlarmCountIsLoading, setPopAlarmCountIsLoading] = useState(true);

  const [electricityAlarmCount, setElectricityAlarmCount] = useState([]);
  const [electricityAlarmCountIsLoading, setElectricityAlarmCountIsLoading] = useState(true);

  const [temperatureAlarmCount, setTemperatureAlarmCount] = useState([]);
  const [temperatureAlarmCountIsLoading, setTemperatureAlarmCountIsLoading] = useState(true);

  const [upsAlarmCount, setUpsAlarmCount] = useState([]);
  const [upsAlarmCountIsLoading, setUpsAlarmCountIsLoading] = useState(true);

  const [genaratorRunningCount, setGenaratorRunningCount] = useState([]);
  const [genaratorRunningCountIsLoading, setGenaratorRunningCountIsLoading] = useState(true);

  const [genaratorNotRunningCount, setGenaratorNotRunningCount] = useState([]);
  const [genaratorNotRunningCountIsLoading, setGenaratorNotRunningCountIsLoading] = useState(true);

  const [deviceResponseCount, setDeviceResponseCount] = useState([]);



  const totalPoPCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalpopfetch")
      .then(({ data }) => {
        setPopCount(data);
        setPopCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }
  const PhaseCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalphasealarm")
      .then(({ data }) => {
        setPhaseCount(data);
        setPhaseCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }

  const PoPAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalalarmpopfetch")
      .then(({ data }) => {
        setPopAlarmCount(data);
        setPopAlarmCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }
  const ElectricityAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalelectricityalarm")
      .then(({ data }) => {
        setElectricityAlarmCount(data);
        setElectricityAlarmCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }

  const TemperatureAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totaltemperaturealarm")
      .then(({ data }) => {
        setTemperatureAlarmCount(data);
        setTemperatureAlarmCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }

  const UpsAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalupsalarm")
      .then(({ data }) => {
        setUpsAlarmCount(data);
        setUpsAlarmCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }

  const GeneratorRunningAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalugenaratorrunning")
      .then(({ data }) => {
        setGenaratorRunningCount(data);
        setGenaratorRunningCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }
  const GeneratorNotRunningAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/totalugenaratornotrunning")
      .then(({ data }) => {
        setGenaratorNotRunningCount(data);
        setGenaratorNotRunningCountIsLoading(false);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }
  const DeviceResponseAlarmCount = async () => {
    await axios.get("https://pab.race.net.bd/api/deviceresponsalarm")
      .then(({ data }) => {
        setDeviceResponseCount(data);
      })
      .catch(({ message }) => {
        console.log(message);
      })
  }

  const autoRefresh = () => {
    // totalPoP
    setPopCountIsLoading(true);
    setPopCount("");
    totalPoPCount();
    // Phase Ok
    setPhaseCountIsLoading(true);
    setPhaseCount("");
    PhaseCount();
    // PoP Alarm
    setPopAlarmCountIsLoading(true);
    setPopAlarmCount("");
    PoPAlarmCount();
    // Electricity Alarm
    setElectricityAlarmCountIsLoading(true);
    setElectricityAlarmCount("");
    ElectricityAlarmCount();
    // Temperature Alarm
    setTemperatureAlarmCountIsLoading(true);
    setTemperatureAlarmCount("");
    TemperatureAlarmCount();
    // Ups Alarm
    setUpsAlarmCountIsLoading(true);
    setUpsAlarmCount("");
    UpsAlarmCount();
    // Generator Running Alarm
    setGenaratorRunningCountIsLoading(true);
    setGenaratorRunningCount("");
    GeneratorRunningAlarmCount();
    // Generator Not Running Alarm
    setGenaratorNotRunningCountIsLoading(true);
    setGenaratorNotRunningCount("");
    GeneratorNotRunningAlarmCount();
    // Device Reponse Failed
    setDeviceResponseCount("");
    DeviceResponseAlarmCount();

  }

  useEffect(() => {
    totalPoPCount();
    PhaseCount();
    PoPAlarmCount();
    ElectricityAlarmCount();
    TemperatureAlarmCount();
    UpsAlarmCount();
    GeneratorRunningAlarmCount();
    GeneratorNotRunningAlarmCount();
    DeviceResponseAlarmCount();
  }, []);


  useEffect(() => {
    const intervalCall = setInterval(() => {
      setRefreshTime(new Date());
      autoRefresh();
    }, 50000);
    return () => {
      clearInterval(intervalCall);
    };

  }, []);

  return (

    <div className="container-fluid">

      <div className='row'>
        <div className='col-md-12 d-flex justify-content-end'>
          {lastRefresh && <p className='text-secondary'><b><i>Last Refreshed : </i>{moment(lastRefresh).format('MMMM Do YYYY, h:mm:ss A')}</b></p>}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-6">
          <div className="small-box bg-secondary">
            <div className="inner">
              {popCountIsLoading ? <h3>0<Loader /></h3> : <h3>{popCount && popCount[0] && popCount[0].Total_pop_count > 0 ? popCount[0].Total_pop_count : 0}</h3>}
              <p>Total PoP</p>
            </div>
            <div className="icon">
              <FaNetworkWired />
            </div>
            <Link to="/dashboard/totalpopdetails" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className={`small-box ${popAlarmCount && popAlarmCount[0] && popAlarmCount[0].pop_alarm > 0 ? `blink` : `bg-secondary`}`}>
            <div className="inner">
              {popAlarmCountIsLoading ? <h3>0<Loader /></h3> : <h3>{popAlarmCount && popAlarmCount[0] && popAlarmCount[0].pop_alarm > 0 ? popAlarmCount[0].pop_alarm : 0}</h3>}
              <p>PoP Alarm</p>
            </div>
            {popAlarmCount && popAlarmCount[0] && popAlarmCount[0].pop_alarm > 0 && popAlarmMuted ?
              <button type='button' className='muted-btn' onClick={() => setPopAlarmMuted(false)}><BsFillBellFill /></button>
              :
              popAlarmCount && popAlarmCount[0] && popAlarmCount[0].pop_alarm > 0 && !popAlarmMuted ?
                <button type='button' className='muted-btn' onClick={() => setPopAlarmMuted(true)}><BsFillBellSlashFill /></button>
                : ''}
            <div className="icon">
              <FaNetworkWired />
            </div>
            <Link to="/dashboard/popalarmdetails" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className={`small-box ${electricityAlarmCount && electricityAlarmCount[0] && electricityAlarmCount[0].electricity_alarm > 0 ? `blink` : `bg-secondary`}`}>
            <div className="inner">
              {electricityAlarmCountIsLoading ? <h3>0<Loader /></h3> : <h3>{electricityAlarmCount && electricityAlarmCount[0] && electricityAlarmCount[0].electricity_alarm > 0 ? electricityAlarmCount[0].electricity_alarm : 0}</h3>}
              <p>Electricity Alarm</p>
            </div>
            {electricityAlarmCount && electricityAlarmCount[0] && electricityAlarmCount[0].electricity_alarm > 0 && electricityAlarmMuted ?
              <button type='button' className='muted-btn' onClick={() => setElectricityAlarmMuted(false)}><BsFillBellFill /></button>
              :
              electricityAlarmCount && electricityAlarmCount[0] && electricityAlarmCount[0].electricity_alarm > 0 && !electricityAlarmMuted ?
                <button type='button' className='muted-btn' onClick={() => setElectricityAlarmMuted(true)}><BsFillBellSlashFill /></button>
                : ''}
            <div className="icon">
              <SiAmp />
            </div>
            <Link to="/dashboard/electricityalarmdetails" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className={`small-box ${temperatureAlarmCount && temperatureAlarmCount[0] && temperatureAlarmCount[0].temperature_alarm > 0 ? `blink` : `bg-secondary`}`}>
            <div className="inner">
              {temperatureAlarmCountIsLoading ? <h3>0<Loader /></h3> : <h3>{temperatureAlarmCount && temperatureAlarmCount[0] && temperatureAlarmCount[0].temperature_alarm > 0 ? temperatureAlarmCount[0].temperature_alarm : 0}</h3>}
              <p>Temperature Alarm</p>
            </div>
            {temperatureAlarmCount && temperatureAlarmCount[0] && temperatureAlarmCount[0].temperature_alarm > 0 && muted ?
              <button type='button' className='muted-btn' onClick={() => setMuted(false)}><BsFillBellFill /></button>
              :
              temperatureAlarmCount && temperatureAlarmCount[0] && temperatureAlarmCount[0].temperature_alarm > 0 && !muted ?
                <button type='button' className='muted-btn' onClick={() => setMuted(true)}><BsFillBellSlashFill /></button>
                : ''}
            <div className="icon">
              <RiTempHotLine />
            </div>
            <Link to="/dashboard/temperaturealarmdetails" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 col-6">
          <div className={`small-box ${upsAlarmCount && upsAlarmCount[0] && upsAlarmCount[0].ups_alarm > 0 ? `blink` : `bg-secondary`}`}>
            <div className="inner">
              {upsAlarmCountIsLoading ? <h3>0<Loader /></h3> : <h3>{upsAlarmCount && upsAlarmCount[0] && upsAlarmCount[0].ups_alarm > 0 ? upsAlarmCount[0].ups_alarm : 0}</h3>}
              <p>UPS Alarm</p>
            </div>
            {upsAlarmCount && upsAlarmCount[0] && upsAlarmCount[0].ups_alarm > 0 && upsAlarmMuted ?
              <button type='button' className='muted-btn' onClick={() => setUpsAlarmMuted(false)}><BsFillBellFill /></button>
              :
              upsAlarmCount && upsAlarmCount[0] && upsAlarmCount[0].ups_alarm > 0 && !upsAlarmMuted ?
                <button type='button' className='muted-btn' onClick={() => setUpsAlarmMuted(true)}><BsFillBellSlashFill /></button>
                : ''}
            <div className="icon">
              <FaUps />
            </div>
            <Link to="/dashboard/upsalarmdetails" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className="small-box bg-secondary">
            <div className="inner">
              {genaratorRunningCountIsLoading ? <h3>0<Loader /></h3> : <h3>{genaratorRunningCount && genaratorRunningCount[0] && genaratorRunningCount[0].genarator_running > 0 ? genaratorRunningCount[0].genarator_running : 0}</h3>}
              <p>Generator Running</p>
            </div>
            <div className="icon">
              <RiRestartFill />
            </div>
            <Link to="/dashboard/genaratorrunning" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className={`small-box ${genaratorNotRunningCount && genaratorNotRunningCount[0] && genaratorNotRunningCount[0].genarator_not_running > 0 ? `blink` : `bg-secondary`}`}>
            <div className="inner">
              {genaratorNotRunningCountIsLoading ? <h3>0<Loader /></h3> : <h3>{genaratorNotRunningCount && genaratorNotRunningCount[0] && genaratorNotRunningCount[0].genarator_not_running > 0 ? genaratorNotRunningCount[0].genarator_not_running : 0}</h3>}
              <p>Generator Alarm</p>
            </div>
            {genaratorNotRunningCount && genaratorNotRunningCount[0] && genaratorNotRunningCount[0].genarator_not_running > 0 && generatorAlarmMuted ?
              <button type='button' className='muted-btn' onClick={() => setGeneratorAlarmMuted(false)}><BsFillBellFill /></button>
              :
              genaratorNotRunningCount && genaratorNotRunningCount[0] && genaratorNotRunningCount[0].genarator_not_running > 0 && !generatorAlarmMuted ?
                <button type='button' className='muted-btn' onClick={() => setGeneratorAlarmMuted(true)}><BsFillBellSlashFill /></button>
                : ''}
            <div className="icon">
              <GiPowerGenerator />
            </div>
            <Link to="/dashboard/genaratornotrunning" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className={`small-box ${phaseCount && phaseCount[0] && phaseCount[0].phase_alarm > 0 ? `blink_green` : `bg-secondary`}`}>
            <div className="inner">
              {phaseCountIsLoading ? <h3>0<Loader /></h3> : <h3>{phaseCount && phaseCount[0] && phaseCount[0].phase_alarm > 0 ? phaseCount[0].phase_alarm : 0}</h3>}
              <p>Phase Sequence Alarm</p>
            </div>
            {phaseCount && phaseCount[0] && phaseCount[0].phase_alarm > 0 && phaseAlarmMuted ?
              <button type='button' className='muted-btn' onClick={() => setPhaseAlarmMuted(false)}><BsFillBellFill /></button>
              :
              phaseCount && phaseCount[0] && phaseCount[0].phase_alarm > 0 && !phaseAlarmMuted ?
                <button type='button' className='muted-btn' onClick={() => setPhaseAlarmMuted(true)}><BsFillBellSlashFill /></button>
                : ''}
            <div className="icon">
              <MdElectricalServices />
            </div>
            <Link to="/dashboard/phasealarmdetails" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></Link>
          </div>
        </div>

      </div>

      <div className="row">
        {temperatureAlarmCount && temperatureAlarmCount[0] && temperatureAlarmCount[0].temperature_alarm > 0 ? <div style={{ display: 'none' }}>
          <ReactAudioPlayer
            src="./assets/audio/alarm.mp3"
            autoPlay
            controls
            loop
            muted={muted ? false : true}
          />
        </div> : ``}

        {popAlarmCount && popAlarmCount[0] && popAlarmCount[0].pop_alarm > 0 ? <div style={{ display: 'none' }}>
          <ReactAudioPlayer
            src="./assets/audio/alarm.mp3"
            autoPlay
            controls
            loop
            muted={popAlarmMuted ? false : true}
          />
        </div> : ``}

        {electricityAlarmCount && electricityAlarmCount[0] && electricityAlarmCount[0].electricity_alarm > 0 ? <div style={{ display: 'none' }}>
          <ReactAudioPlayer
            src="./assets/audio/alarm.mp3"
            autoPlay
            controls
            loop
            muted={electricityAlarmMuted ? false : true}
          />
        </div> : ``}

        {upsAlarmCount && upsAlarmCount[0] && upsAlarmCount[0].ups_alarm > 0 ? <div style={{ display: 'none' }}>
          <ReactAudioPlayer
            src="./assets/audio/alarm.mp3"
            autoPlay
            controls
            loop
            muted={upsAlarmMuted ? false : true}
          />
        </div> : ``}

        {genaratorNotRunningCount && genaratorNotRunningCount[0] && genaratorNotRunningCount[0].genarator_not_running > 0 ? <div style={{ display: 'none' }}>
          <ReactAudioPlayer
            src="./assets/audio/alarm.mp3"
            autoPlay
            controls
            loop
            muted={generatorAlarmMuted ? false : true}
          />
        </div> : ``}

        {/*{phaseCount && phaseCount[0] && phaseCount[0].phase_alarm > 0 ? <div style={{ display: 'none' }}>*/}
        {/*  <ReactAudioPlayer*/}
        {/*    src="./assets/audio/alarm.mp3"*/}
        {/*    autoPlay*/}
        {/*    controls*/}
        {/*    loop*/}
        {/*    muted={upsAlarmMuted ? false : true}*/}
        {/*  />*/}
        {/*</div> : ``}*/}

      </div>
      {deviceResponseCount && deviceResponseCount[0] && deviceResponseCount[0].total_pop_count > 0 ?
        <div className='row'>
          <div className='col-md-12 d-flex justify-content-end'>
            <Link to="/dashboard/deviceresponsealarmdetails" className='btn btn-seconday text-white ml-2 fw-bold' style={{ background: '#6c757d' }}> ** Device Response Failed :  <span className='bg-danger px-2 py-1 rounded'>{deviceResponseCount && deviceResponseCount[0] ? deviceResponseCount[0].total_pop_count : 0}</span>
              {/* {deviceResponseCount && deviceResponseCount[0] ? `** Device Response Failed :` + <span>deviceResponseCount[0].total_pop_count</span> : 0} */}
            </Link>
          </div>
        </div>
        : ''
      }

    </div>


  )

}
