import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Swal from 'sweetalert2';

export const EditUser = ({ userid, modalClose, tableRefresh }) => {
    const [name, setFullName] = useState("");
    const [username, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [role, setRole] = useState("");
    const [status, setStatus] = useState("");
    const [is_email_notification, setIsEmailNotification] = useState("");
    const [password, setPassword] = useState("");
    const [validationError, setValidationError] = useState({});

    useEffect(() => {
        userListFetchedByID();
    }, [])

    const userListFetchedByID = async () => {
        await axios.get(`https://pab.race.net.bd/api/userlistedit/${userid}`).then(({ data }) => {
            console.log(data.user);
            const { name, username, email, mobile, role, status, is_email_notification, password } = data.user
            setFullName(name)
            setUserName(username)
            setEmail(email)
            setMobile(mobile)
            setRole(role)
            setStatus(status)
            setIsEmailNotification(is_email_notification)
            setPassword(password)
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    const updateUser = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('name', name)
        formData.append('username', username)
        formData.append('email', email)
        formData.append('mobile', mobile)
        formData.append('role', role)
        formData.append('status', status)
        formData.append('is_email_notification', is_email_notification)
        formData.append('password', password)


        await axios.post(`https://pab.race.net.bd/api/userupdate/${userid}`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            modalClose();
            tableRefresh();
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="form-wrapper">
            {
                Object.keys(validationError).length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger">
                                <ul className="mb-0">
                                    {
                                        Object.entries(validationError).map(([key, value]) => (
                                            <li key={key}>{value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
            <Form onSubmit={updateUser}>
                <Row>
                    <Col>
                        <Form.Group controlId="fullName">
                            <Form.Label>Fullname</Form.Label>
                            <Form.Control type="text" value={name} onChange={(event) => {
                                setFullName(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="userName">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={username} onChange={(event) => {
                                setUserName(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" value={email} onChange={(event) => {
                                setEmail(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="mobile">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control type="text" value={mobile} onChange={(event) => {
                                setMobile(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="role">
                            <Form.Label>Role</Form.Label>
                            {/* <Form.Control type="text" value={role} onChange={(event) => {
                                setRole(event.target.value)
                            }} /> */}
                            <Form.Select aria-label="Default select example" value={role} onChange={(event) => {
                                setRole(event.target.value)
                            }} >
                                <option>--Select Role--</option>
                                <option value="Super Admin">Super Admin</option>
                                <option value="Admin">Admin</option>
                                <option value="Guest">Guest</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="status">
                            <Form.Label>Status</Form.Label>
                            {/* 
                            <Form.Control type="text" value={status} onChange={(event) => {
                                setStatus(event.target.value)
                            }} /> */}
                            <Form.Select aria-label="Default select example" value={status} onChange={(event) => {
                                setStatus(event.target.value)
                            }} >
                                <option>--Select Status--</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="isEmailNotification">
                            <Form.Label>Allow Email Notification</Form.Label>
                            {/* <Form.Control type="text" value={is_email_notification} onChange={(event) => {
                                setIsEmailNotification(event.target.value)
                            }} /> */}
                            <Form.Select aria-label="Default select example" value={is_email_notification} onChange={(event) => {
                                setIsEmailNotification(event.target.value)
                            }} >
                                <option>--Select Email--</option>
                                <option value="1">True</option>
                                <option value="0">False</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(event) => {
                                setPassword(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" className="float-right" type="submit">
                    Update
                </Button>
            </Form>
        </div>
    )
}
