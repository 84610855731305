import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useFormik, resetForm } from 'formik';
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom';
import { ImPower } from 'react-icons/im';
import { FaPenAlt, FaPowerOff } from 'react-icons/fa';
import { MdElectricalServices } from 'react-icons/md';
import { BiSave } from 'react-icons/bi';
import { RxCross2, RxCrossCircled } from 'react-icons/rx';
import { FaUps, FaTemperatureHigh } from 'react-icons/fa';
import { WiHumidity } from 'react-icons/wi';
import { GiPowerGenerator } from 'react-icons/gi';
import { TbAirConditioning, TbAirConditioningDisabled } from "react-icons/tb";
import { AlterTimeFromValidation } from '../../schema/Validation';
import { Loader } from './../components/loader/Loader';


export const PopDashboard = () => {
    // user Role
    const role = localStorage.getItem('role');

    const [isDisabled, setIsDisabled] = useState(true);
    const [isSubmitShow, setIsSubmitShow] = useState(false);
    // PoP Dropdown
    const [lastRefresh, setRefreshTime] = useState(new Date());
    const { popid } = useParams();
    const [PoPNameChange, setPoPNameChange] = useState(popid);
    const [showPoPName, setShowPoPName] = useState("");

    const [PoPNameDropdown, setPoPNameDropdown] = useState([]);
    const [temperature, setTemperature] = useState([]);
    const [humidity, setHumidity] = useState([]);
    const [electricity, setElectricity] = useState([]);
    const [generator, setGenerator] = useState([]);
    const [ups, setUPS] = useState([]);
    const [ac, setAC] = useState([]);
    const [phase, setPhase] = useState([]);

    // Loader
    const [temperatureIsLoading, setTemperatureIsLoading] = useState(true);
    const [humidityIsLoading, setHumidityIsLoading] = useState(true);
    const [electricityIsLoading, setElectricityIsLoading] = useState(true);
    const [generatorIsLoading, setGeneratorIsLoading] = useState(true);
    const [upsIsLoading, setUpsIsLoading] = useState(true);
    const [acIsLoading, setAcIsLoading] = useState(true);
    const [phaseIsLoading, setPhaseIsLoading] = useState(true);

    const [checkedAC1, setCheckedAC1] = useState(false);
    const [checkedAC2, setCheckedAC2] = useState(false);
    const [checkedAC3, setCheckedAC3] = useState(false);
    const [checkedAC4, setCheckedAC4] = useState(false);

    const switchHandleAC1 = (event) => {
        if (event.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffOne();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC1(event.target.checked);
                }
            })
        } else if (event.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnOne();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC1(event.target.checked);
                }
            })
        }
    }

    const switchHandleAC2 = (event) => {

        if (event.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffTwo();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC2(event.target.checked);
                }
            })
        } else if (event.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnTwo();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC2(event.target.checked);
                }
            })
        }
    }

    const switchHandleAC3 = (event) => {

        if (event.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffThree();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC3(event.target.checked);
                }
            })
        } else if (event.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnThree();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC3(event.target.checked);
                }
            })
        }
    }

    const switchHandleAC4 = (event) => {

        if (event.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffFour();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC4(event.target.checked);
                }
            })
        } else if (event.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnFour();
                    PoPWiseAC(PoPNameChange);
                    setCheckedAC4(event.target.checked);
                }
            })
        }
    }



    const alterTimeHandler = () => {
        setIsDisabled(!isDisabled);
        setIsSubmitShow(true);
    }


    const alterTimeCancelHandler = () => {
        setIsSubmitShow(false);
        setIsDisabled(!isDisabled);
    }

    const handleChange = (e) => {
        const popid = e.target.value;
        if (popid !== "") {
            setPoPNameChange(popid);
            singlePoPNameFetch(popid);
            temperaturePoPWise(popid);
            humidityPoPWise(popid);
            electricityPoPWise(popid);
            generatorPoPWise(popid);
            PoPWiseUPS(popid);
            PoPWiseAC(popid);
            phasePoPWise(popid);
        }

    }


    const PoPNameFetch = async () => {
        await axios.get(`https://pab.race.net.bd/api/popfetch`)
            .then(({ data }) => {
                setPoPNameDropdown(data)
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    const singlePoPNameFetch = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwisesinglepopnamefetch?popid=${popid}`)
            .then(({ data }) => {
                setShowPoPName(data)
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    // end PoP Dropdown


    // Temperature
    const temperaturePoPWise = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwisetemperature?popid=${popid}`)
            .then(({ data }) => {
                setTemperature(data);
                setTemperatureIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    // Humidity
    const humidityPoPWise = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwisehumidity?popid=${popid}`)
            .then(({ data }) => {
                setHumidity(data);
                setHumidityIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    // Electricity
    const electricityPoPWise = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwiseelectricity?popid=${popid}`)
            .then(({ data }) => {
                setElectricity(data);
                setElectricityIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    // Phase
    const phasePoPWise = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwisephase?popid=${popid}`)
            .then(({ data }) => {
                setPhase(data);
                setPhaseIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }


    // Generator
    const generatorPoPWise = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwisegenerator?popid=${popid}`)
            .then(({ data }) => {
                setGenerator(data);
                setGeneratorIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    // UPS
    const PoPWiseUPS = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwiseups?popid=${popid}`)
            .then(({ data }) => {
                setUPS(data);
                setUpsIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }

    // AC
    const PoPWiseAC = async (popid) => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardpopwiseac?popid=${popid}`)
            .then(({ data }) => {
                setAC(data);

                if (data[0] && data[0].ac_1_status === "Running") {
                    setCheckedAC1(true);
                } else {
                    setCheckedAC1(false);
                }
                if (data[0] && data[0].ac_2_status === "Running") {
                    setCheckedAC2(true);
                } else {
                    setCheckedAC2(false);
                }
                if (data[0] && data[0].ac_3_status === "Running") {
                    setCheckedAC3(true);
                } else {
                    setCheckedAC3(false);
                }
                if (data[0] && data[0].ac_4_status === "Running") {
                    setCheckedAC4(true);
                } else {
                    setCheckedAC4(false);
                }

                setAcIsLoading(false);
            })
            .catch(({ message }) => {
                console.log(message);
            })
    }


    // AlterTimeChange
    const formik = useFormik({
        initialValues: {
            altTime: '',
        },
        validationSchema: AlterTimeFromValidation,
        onSubmit: (values, { resetForm }) => {

            axios.post(`https://pab.race.net.bd/api/popdashboardalternatetimeset?popid=${PoPNameChange}&alttime=${values.altTime}`)
                .then((success) => {
                    resetForm();
                    setIsDisabled(!isDisabled);
                    setIsSubmitShow(false);
                    Swal.fire({
                        text: "Alternate Time Added Successfully",
                        icon: "success"
                    })
                    PoPWiseAC(PoPNameChange);
                })
                .catch((error) => {
                    Swal.fire({
                        text: error.message,
                        icon: "error"
                    })
                });
        }
    });

    // Ac Control
    const acOnOFFHandleOne = (e) => {
        if (e.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffOne();
                    PoPWiseAC(popid);
                }
            })
        } else if (e.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnOne();
                    PoPWiseAC(popid);
                }
            })
        }

    }

    const acOnOFFHandleTwo = (e) => {
        if (e.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffTwo();
                    PoPWiseAC(popid);
                }
            })
        } else if (e.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnTwo();
                    PoPWiseAC(popid);
                }
            })
        }

    }
    const acOnOFFHandleThree = (e) => {
        if (e.currentTarget.value === 'Running') {

            Swal.fire({
                title: 'Do you want to TURN OFF ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOffThree();
                    PoPWiseAC(popid);
                }
            })
        } else if (e.currentTarget.value === 'Standby') {

            Swal.fire({
                title: 'Do you want to TURN ON ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.isConfirmed) {
                    acOnThree();
                    PoPWiseAC(popid);
                }
            })
        }

    }

    const acOnOne = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardaconone?popid=${PoPNameChange}&on=${1}`)
            .then((success) => {
                Swal.fire(
                    'TURNED ON !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOffOne = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardacoffone?popid=${PoPNameChange}&off=${0}`)
            .then((success) => {
                Swal.fire(
                    'TURNED OFF !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOnTwo = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardacontwo?popid=${PoPNameChange}&on=${1}`)
            .then((success) => {
                Swal.fire(
                    'TURNED ON !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOffTwo = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardacofftwo?popid=${PoPNameChange}&off=${0}`)
            .then((success) => {
                Swal.fire(
                    'TURNED OFF !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOnThree = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardaconthree?popid=${PoPNameChange}&on=${1}`)
            .then((success) => {
                Swal.fire(
                    'TURNED ON !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOffThree = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardacoffthree?popid=${PoPNameChange}&off=${0}`)
            .then((success) => {
                Swal.fire(
                    'TURNED OFF !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOnFour = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardaconfour?popid=${PoPNameChange}&on=${1}`)
            .then((success) => {
                Swal.fire(
                    'TURNED ON !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    const acOffFour = async () => {
        await axios.post(`https://pab.race.net.bd/api/popdashboardacofffour?popid=${PoPNameChange}&off=${0}`)
            .then((success) => {
                Swal.fire(
                    'TURNED OFF !',
                    '',
                    'success'
                )
            })
            .catch((error) => {
                Swal.fire({
                    text: error.message,
                    icon: "error"
                })
            });
    }

    // const autoRefresh = () => {
    //     setShowPoPName("");
    //     singlePoPNameFetch(popid);
    //     // Temperature
    //     setTemperatureIsLoading(true);
    //     setTemperature("");
    //     temperaturePoPWise(popid);
    //     // Humidity
    //     setHumidityIsLoading(true);
    //     setHumidity("");
    //     humidityPoPWise(popid);
    //     // Electricity
    //     setElectricityIsLoading(true);
    //     setElectricity("");
    //     electricityPoPWise(popid);
    //     // Generator
    //     setGeneratorIsLoading(true);
    //     setGenerator("");
    //     generatorPoPWise(popid);
    //     // UPS
    //     setUpsIsLoading(true);
    //     setUPS("");
    //     PoPWiseUPS(popid);
    //     // AC
    //     setAcIsLoading(true);
    //     setAC("");
    //     PoPWiseAC(popid);
    // }

    useEffect(() => {
        PoPNameFetch();
    }, []);

    useEffect(() => {
        singlePoPNameFetch(popid);
        temperaturePoPWise(popid);
        humidityPoPWise(popid);
        electricityPoPWise(popid);
        generatorPoPWise(popid);
        PoPWiseUPS(popid);
        PoPWiseAC(popid);
        phasePoPWise(popid);
    }, [popid]);

    // useEffect(() => {
    //     const intervalCall = setInterval(() => {
    //         setRefreshTime(new Date());
    //         autoRefresh();
    //     }, 30000);
    //     return () => {
    //         clearInterval(intervalCall);
    //     };
    // }, []);



    return (
        <>
            <div className="container-fluid">

                <div className='row mb-3'>
                    <div className='col-md-12'>
                        <div className='rounded' style={{ background: '#03356a63', padding: '5px', color: 'white' }}>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <h6 className='m-0 form-control' style={{ background: 'transparent', border: 'none', fontWeight: 'bold', color: 'white' }}>PoP : {showPoPName && showPoPName[0] ? showPoPName[0].pop_name : '--'}</h6>
                                </div>
                                <div className='col-md-4'>
                                    <select
                                        style={{ fontSize: '14px' }}
                                        className="form-select form-control" name='popname'
                                        onChange={handleChange}
                                    >
                                        <option selected value="">--Select PoP Name--</option>
                                        {PoPNameDropdown.length > 0 && PoPNameDropdown.map((option, index) => (
                                            <option key={index} value={option.pop_id}>{option.pop_name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row">
                    <div className="col-lg-3 col-6">
                        <div className={`small-box ${temperature && temperature[0] && temperature[0].temperature > temperature[0].temperature_threshold ? `bg-danger` : ``}`}>
                            <div className="inner">
                                {temperatureIsLoading ? <h3 style={{ fontSize: '24px' }}>0<Loader /></h3> : <h3 style={{ fontSize: '24px' }}>
                                    {temperature && temperature[0] ? temperature[0].temperature : 0} <sup>o</sup>C</h3>}
                                <p>Temperature</p>
                            </div>
                            <div className="icon">
                                <FaTemperatureHigh />
                            </div>
                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${temperature && temperature[0] && temperature[0].temperature > temperature[0].temperature_threshold ? `red` : `darkgrey`}`, color: 'white' }}>
                                Threshold : {temperature && temperature[0] ? temperature[0].temperature_threshold : 0} <sup>o</sup>C
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className={`small-box ${humidity && humidity[0] && humidity[0].humidity > humidity[0].humidity_threshold ? `bg-danger` : ``}`}>
                            <div className="inner">
                                {humidityIsLoading ? <h3 style={{ fontSize: '24px' }}>0<Loader /></h3> : <h3 style={{ fontSize: '24px' }}>
                                    {humidity && humidity[0] ? humidity[0].humidity : 0} %
                                </h3>}
                                <p>Humidity</p>
                            </div>
                            <div className="icon">
                                <WiHumidity />
                            </div>
                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${humidity && humidity[0] && humidity[0].humidity > humidity[0].humidity_threshold ? `red` : `darkgrey`}`, color: 'white' }}>
                                Threshold : {humidity && humidity[0] ? humidity[0].humidity_threshold : 0} %
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className={`small-box ${electricity && electricity[0] && electricity[0].electricty !== "Available" ? `bg-danger` : ``}`}>
                            <div className="inner">
                                {electricityIsLoading ? <h3 style={{ fontSize: '24px' }}>0<Loader /></h3> : <h3 style={{ fontSize: '24px' }}>
                                    {electricity && electricity[0] ? electricity[0].electricty : <h3 style={{ visibility: 'hidden', fontSize: '24px' }}>hidden</h3>}
                                </h3>}

                                <p style={{ visibility: 'hidden' }}>Electricity</p>
                            </div>
                            <div className="icon">
                                <ImPower />
                            </div>
                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${electricity && electricity[0] && electricity[0].electricty !== "Available" ? `red` : `darkgrey`}`, color: 'white' }}>
                                Electricity
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-6">
                        <div className={`small-box ${generator && generator[0] && generator[0].status == 3 ? `bg-danger` : ``}`}>
                            <div className="inner">
                                {generatorIsLoading ? <h3 style={{ fontSize: '24px' }}>0<Loader /></h3> : <h3 style={{ fontSize: '24px' }}>
                                    {generator && generator[0] ? generator[0].generator : <h3 style={{ visibility: 'hidden', fontSize: '24px' }}>hidden</h3>}
                                </h3>}

                                <p>Generator </p>
                            </div>
                            <div className="icon">
                                <GiPowerGenerator />
                            </div>
                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${generator && generator[0] && generator[0].status == 3 ? `red` : `darkgrey`}`, color: 'white' }}>
                                Capacity : {generator && generator[0] ? `${generator[0].generator_description} kVA` : ' '}
                            </a>
                        </div>
                    </div>
                </div> */}


                {/* <div className="row">
                    <div className="col-md-6 col-lg-6">
                        {upsIsLoading ? <div style={{ position: 'relative', height: '173px' }}><Loader /></div> :
                            <div className="pop-monitoring-ac-box pt-3 px-3">
                                <div className='row'>
                                    <div className="col-lg-4 col-6 p-2">
                                        <div className={`small-box ${ups && ups[0] && ups[0].ups_1 === "Faulty" ? `bg-danger` : ``}`}>
                                            <div className="inner text-center">
                                                <h3 style={{ fontSize: '30px', marginLeft: '-36px' }}>
                                                    {ups && ups[0] && ups[0].ups_1 === "N/A" ? <RxCrossCircled /> : <FaUps />}
                                                    - <span style={{ borderRadius: '5px', width: '25px', height: '25px', position: 'absolute', fontSize: '20px', lineHeight: '18px', marginTop: '8px', border: '2px solid #646262ed', marginLeft: '3px' }}>1</span></h3>
                                                <p style={{ fontWeight: 'bolder' }}>
                                                    {ups && ups[0] ? ups[0].ups_1 : <p style={{ visibility: 'hidden' }}>hidden</p>}
                                                </p>
                                            </div>
                                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${ups && ups[0] && ups[0].ups_1 === "Faulty" ? `red` : `darkgrey`}`, color: 'white', height: '25px' }}>
                                                {ups && ups[0] ? ups[0].ups_description : ''}
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6 p-2">
                                        <div className={`small-box ${ups && ups[1] && ups[1].ups_2 === "Faulty" ? `bg-danger` : ``}`}>
                                            <div className="inner text-center">
                                                <h3 style={{ fontSize: '30px', marginLeft: '-36px' }}>
                                                    {ups && ups[1] && ups[1].ups_2 === "N/A" ? <RxCrossCircled /> : <FaUps />}
                                                    - <span style={{ borderRadius: '5px', width: '25px', height: '25px', position: 'absolute', fontSize: '20px', lineHeight: '18px', marginTop: '8px', border: '2px solid #646262ed', marginLeft: '3px' }}>2</span></h3>
                                                <p style={{ fontWeight: 'bolder' }}>
                                                    {ups && ups[1] ? ups[1].ups_2 : <p style={{ visibility: 'hidden' }}>hidden</p>}
                                                </p>
                                            </div>
                                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${ups && ups[1] && ups[1].ups_2 === "Faulty" ? `red` : `darkgrey`}`, color: 'white', height: '25px' }}>
                                                {ups && ups[1] ? ups[1].ups_description : ''}
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6 p-2">
                                        <div className={`small-box ${ups && ups[2] && ups[2].ups_3 === "Faulty" ? `bg-danger` : ``}`}>
                                            <div className="inner text-center">
                                                <h3 style={{ fontSize: '30px', marginLeft: '-36px' }}>
                                                    {ups && ups[2] && ups[2].ups_3 === "N/A" ? <RxCrossCircled /> : <FaUps />}
                                                    - <span style={{ borderRadius: '5px', width: '25px', height: '25px', position: 'absolute', fontSize: '20px', lineHeight: '18px', marginTop: '8px', border: '2px solid #646262ed', marginLeft: '3px' }}>3</span></h3>
                                                <p style={{ fontWeight: 'bolder' }}>
                                                    {ups && ups[2] ? ups[2].ups_3 : <p style={{ visibility: 'hidden' }}>hidden</p>}
                                                </p>
                                            </div>
                                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${ups && ups[2] && ups[2].ups_3 === "Faulty" ? `red` : `darkgrey`}`, color: 'white', height: '25px' }}>
                                                {ups && ups[2] ? ups[2].ups_description : ''}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-md-6 col-lg-6">
                        {acIsLoading ? <div style={{ position: 'relative', height: '278px' }}><Loader /></div> :
                            <div className="pop-monitoring-ac-box py-4 px-3">

                                <div className='row'>
                                    <div className="col-lg-4 col-6">
                                        <div className={`small-box ${ac && ac[0] && ac[0].ac_1 === "Standby" && ac[0].ac_2 === "Standby" && ac[0].ac_3 === "Standby" ? `bg-danger` : ``}`}>
                                            <div className="inner text-center">
                                                <h3 style={{ fontSize: '30px', marginLeft: '-36px' }}>
                                                    {ac && ac[0] && ac[0].ac_1 === "Running" ? <TbAirConditioning /> : ac && ac[0] && ac[0].ac_1 === "Standby" ? <TbAirConditioningDisabled /> : <RxCrossCircled />}
                                                    - <span style={{ borderRadius: '5px', width: '25px', height: '25px', position: 'absolute', fontSize: '20px', lineHeight: '18px', marginTop: '8px', border: '2px solid #646262ed', marginLeft: '3px' }}>1</span></h3>
                                                <p style={{ fontWeight: 'bolder' }}>
                                                    {ac && ac[0] ? ac[0].ac_1 : <p style={{ visibility: 'hidden' }}>hidden</p>}
                                                </p>

                                                {ac && ac[0] && ac[0].ac_1 === "Running" ?
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '70px',
                                                        height: '30px',
                                                        background: 'gray',
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        margin: '0 auto'
                                                    }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            height: '100%',
                                                            fontSize: '14px',
                                                            fontWeight: ' 700',
                                                            lineHeight: '30px',
                                                            background: 'green',
                                                            color: 'white',
                                                            borderRadius: '30px 0px 0px 30px'
                                                        }}>
                                                            ON
                                                        </div>
                                                        <button type='button' style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            right: '0',
                                                            height: '100%',
                                                            border: 'none',
                                                            outline: 'none',
                                                            background: '#a9a9a9',
                                                            color: 'white',
                                                            borderRadius: '0 30px 30px 0'
                                                        }} onClick={acOnOFFHandleOne}
                                                            value={ac && ac[0] && ac[0].ac_1}>
                                                            <FaPowerOff />
                                                        </button>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '70px',
                                                        height: '30px',
                                                        background: 'gray',
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        margin: '0 auto'
                                                    }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            height: '100%',
                                                            fontSize: '14px',
                                                            fontWeight: ' 700',
                                                            lineHeight: '30px',
                                                            background: 'red',
                                                            color: 'white',
                                                            borderRadius: '30px 0px 0px 30px'
                                                        }}>
                                                            OFF
                                                        </div>
                                                        <button type='button' style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            right: '0',
                                                            height: '100%',
                                                            border: 'none',
                                                            outline: 'none',
                                                            background: '#a9a9a9',
                                                            color: 'white',
                                                            borderRadius: '0 30px 30px 0'
                                                        }} onClick={acOnOFFHandleOne}
                                                            value={ac && ac[0] && ac[0].ac_1}>
                                                            <FaPowerOff />
                                                        </button>
                                                    </div>}

                                            </div>
                                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${ac && ac[0] && ac[0].ac_1 === "Standby" && ac[0].ac_2 === "Standby" && ac[0].ac_3 === "Standby" ? `red` : `darkgrey`}`, color: 'white' }}>
                                                Capacity : {ac && ac[0] ? `${ac[0].ac_desc_1} Ton` : ''}
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <div className={`small-box ${ac && ac[0] && ac[0].ac_1 === "Standby" && ac[0].ac_2 === "Standby" && ac[0].ac_3 === "Standby" ? `bg-danger` : ``}`}>
                                            <div className="inner text-center">
                                                <h3 style={{ fontSize: '30px', marginLeft: '-36px' }}>
                                                    {ac && ac[0] && ac[0].ac_2 === "Running" ? <TbAirConditioning /> : ac && ac[0] && ac[0].ac_2 === "Standby" ? <TbAirConditioningDisabled /> : <RxCrossCircled />}
                                                    - <span style={{ borderRadius: '5px', width: '25px', height: '25px', position: 'absolute', fontSize: '20px', lineHeight: '18px', marginTop: '8px', border: '2px solid #646262ed', marginLeft: '3px' }}>2</span></h3>
                                                <p style={{ fontWeight: 'bolder' }}>
                                                    {ac && ac[0] ? ac[0].ac_2 : <p style={{ visibility: 'hidden' }}>hidden</p>}
                                                </p>
                                                {ac && ac[0] && ac[0].ac_2 === "Running" ?
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '70px',
                                                        height: '30px',
                                                        background: 'gray',
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        margin: '0 auto'
                                                    }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            height: '100%',
                                                            fontSize: '14px',
                                                            fontWeight: ' 700',
                                                            lineHeight: '30px',
                                                            background: 'green',
                                                            color: 'white',
                                                            borderRadius: '30px 0px 0px 30px'
                                                        }}>
                                                            ON
                                                        </div>
                                                        <button type='button' style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            right: '0',
                                                            height: '100%',
                                                            border: 'none',
                                                            outline: 'none',
                                                            background: '#a9a9a9',
                                                            color: 'white',
                                                            borderRadius: '0 30px 30px 0'
                                                        }} onClick={acOnOFFHandleTwo}
                                                            value={ac && ac[0] && ac[0].ac_2}>
                                                            <FaPowerOff />
                                                        </button>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '70px',
                                                        height: '30px',
                                                        background: 'gray',
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        margin: '0 auto'
                                                    }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            height: '100%',
                                                            fontSize: '14px',
                                                            fontWeight: ' 700',
                                                            lineHeight: '30px',
                                                            background: 'red',
                                                            color: 'white',
                                                            borderRadius: '30px 0px 0px 30px'
                                                        }}>
                                                            OFF
                                                        </div>
                                                        <button type='button' style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            right: '0',
                                                            height: '100%',
                                                            border: 'none',
                                                            outline: 'none',
                                                            background: '#a9a9a9',
                                                            color: 'white',
                                                            borderRadius: '0 30px 30px 0'
                                                        }} onClick={acOnOFFHandleTwo}
                                                            value={ac && ac[0] && ac[0].ac_2}>
                                                            <FaPowerOff />
                                                        </button>
                                                    </div>}

                                            </div>
                                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${ac && ac[0] && ac[0].ac_1 === "Standby" && ac[0].ac_2 === "Standby" && ac[0].ac_3 === "Standby" ? `red` : `darkgrey`}`, color: 'white' }}>
                                                Capacity : {ac && ac[0] ? `${ac[0].ac_desc_2} Ton` : ''}
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-6">
                                        <div className={`small-box ${ac && ac[0] && ac[0].ac_1 === "Standby" && ac[0].ac_2 === "Standby" && ac[0].ac_3 === "Standby" ? `bg-danger` : ``}`}>
                                            <div className="inner text-center">
                                                <h3 style={{ fontSize: '30px', marginLeft: '-36px' }}>
                                                    {ac && ac[0] && ac[0].ac_3 === "Running" ? <TbAirConditioning /> : ac && ac[0] && ac[0].ac_3 === "Standby" ? <TbAirConditioningDisabled /> : <RxCrossCircled />}

                                                    - <span style={{ borderRadius: '5px', width: '25px', height: '25px', position: 'absolute', fontSize: '20px', lineHeight: '18px', marginTop: '8px', border: '2px solid #646262ed', marginLeft: '3px' }}>3</span></h3>
                                                <p style={{ fontWeight: 'bolder' }}>
                                                    {ac && ac[0] ? ac[0].ac_3 : <p style={{ visibility: 'hidden' }}>hidden</p>}
                                                </p>

                                                {ac && ac[0] && ac[0].ac_3 === "Running" ?
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '70px',
                                                        height: '30px',
                                                        background: 'gray',
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        margin: '0 auto'
                                                    }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            height: '100%',
                                                            fontSize: '14px',
                                                            fontWeight: ' 700',
                                                            lineHeight: '30px',
                                                            background: 'green',
                                                            color: 'white',
                                                            borderRadius: '30px 0px 0px 30px'
                                                        }}>
                                                            ON
                                                        </div>
                                                        <button type='button' style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            right: '0',
                                                            height: '100%',
                                                            border: 'none',
                                                            outline: 'none',
                                                            background: '#a9a9a9',
                                                            color: 'white',
                                                            borderRadius: '0 30px 30px 0'
                                                        }} onClick={acOnOFFHandleThree}
                                                            value={ac && ac[0] && ac[0].ac_3}>
                                                            <FaPowerOff />
                                                        </button>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        position: 'relative',
                                                        width: '70px',
                                                        height: '30px',
                                                        background: 'gray',
                                                        borderRadius: '30px',
                                                        display: 'flex',
                                                        margin: '0 auto'
                                                    }}>
                                                        <div style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            height: '100%',
                                                            fontSize: '14px',
                                                            fontWeight: ' 700',
                                                            lineHeight: '30px',
                                                            background: 'red',
                                                            color: 'white',
                                                            borderRadius: '30px 0px 0px 30px'
                                                        }}>
                                                            OFF
                                                        </div>
                                                        <button type='button' style={{
                                                            position: 'absolute',
                                                            width: '50%',
                                                            right: '0',
                                                            height: '100%',
                                                            border: 'none',
                                                            outline: 'none',
                                                            background: '#a9a9a9',
                                                            color: 'white',
                                                            borderRadius: '0 30px 30px 0'
                                                        }} onClick={acOnOFFHandleThree}
                                                            value={ac && ac[0] && ac[0].ac_3}>
                                                            <FaPowerOff />
                                                        </button>
                                                    </div>}


                                            </div>
                                            <a role="button" className="small-box-footer" style={{ cursor: 'default', background: `${ac && ac[0] && ac[0].ac_1 === "Standby" && ac[0].ac_2 === "Standby" && ac[0].ac_3 === "Standby" ? `red` : `darkgrey`}`, color: 'white' }}>
                                                Capacity : {ac && ac[0] ? `${ac[0].ac_desc_3} Ton` : ''}
                                            </a>
                                        </div>
                                    </div>

                                </div>

                                <form onSubmit={formik.handleSubmit}>
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-md-12 col-lg-12'>
                                            <div class="input-group">
                                                <input type="text"
                                                    class="form-control placecolor" style={{ fontSize: '14px' }}
                                                    placeholder={ac && ac[0] && ac[0].alternate_time ? `Current Alternate Time : ${ac && ac[0] && ac[0].alternate_time} Hours` : "Set Alternate Time ( In Hours )"}
                                                    name='altTime'
                                                    disabled={isDisabled}
                                                    value={formik.values.altTime}
                                                    onChange={formik.handleChange} />

                                                {isSubmitShow ?
                                                    <>
                                                        <button type='submit' class="input-group-text btn btn-success" ><BiSave /></button>
                                                        <button type='button' class="input-group-text btn btn-danger ml-2" onClick={alterTimeCancelHandler}><RxCross2 /></button></>
                                                    :
                                                    <button type='button' class="input-group-text btn btn-primary" onClick={alterTimeHandler}><FaPenAlt /></button>
                                                }
                                            </div>
                                            {formik.touched.altTime && formik.errors.altTime ? (
                                                <div className='text-danger'>{formik.errors.altTime}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </form>

                            </div>
                        }
                    </div>

                </div> */}

            </div >


            {/* New Code */}
            <section>
                <div className="container-fluid">
                    <div className='row'>
                        {/* Temperature */}
                        <div className='col-md-6 mb-4'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Temperature</h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <FaTemperatureHigh />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        {temperatureIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${temperature && temperature[0] && temperature[0].aisle_cold_1_temperature > temperature[0].cold_aisle_1_temperature_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{temperature && temperature[0] ? temperature[0].aisle_cold_1_temperature : 0} <sup>o</sup>C</h5>
                                                <p>Cold-1</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {temperature && temperature[0] ? temperature[0].cold_aisle_1_temperature_threshold : 0} <sup>o</sup>C</small>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className='col-md-3'>
                                        {temperatureIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${temperature && temperature[0] && temperature[0].aisle_cold_2_temperature > temperature[0].cold_aisle_2_temperature_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{temperature && temperature[0] ? temperature[0].aisle_cold_2_temperature : 0} <sup>o</sup>C</h5>
                                                <p>Cold-2</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {temperature && temperature[0] ? temperature[0].cold_aisle_2_temperature_threshold : 0} <sup>o</sup>C</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {temperatureIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${temperature && temperature[0] && temperature[0].aisle_hot_1_temperature > temperature[0].hot_aisle_1_temperature_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{temperature && temperature[0] ? temperature[0].aisle_hot_1_temperature : 0} <sup>o</sup>C</h5>
                                                <p>Hot-1</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {temperature && temperature[0] ? temperature[0].hot_aisle_1_temperature_threshold : 0} <sup>o</sup>C</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {temperatureIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${temperature && temperature[0] && temperature[0].aisle_hot_2_temperature > temperature[0].hot_aisle_2_temperature_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{temperature && temperature[0] ? temperature[0].aisle_hot_2_temperature : 0} <sup>o</sup>C</h5>
                                                <p>Hot-2</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {temperature && temperature[0] ? temperature[0].hot_aisle_2_temperature_threshold : 0} <sup>o</sup>C</small>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Temperature */}

                        {/* Humidity */}

                        <div className='col-md-6 mb-4'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Humidity</h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <WiHumidity />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        {humidityIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${humidity && humidity[0] && humidity[0].aisle_cold_1_humidity > humidity[0].cold_aisle_1_humidity_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{humidity && humidity[0] ? humidity[0].aisle_cold_1_humidity : 0} %</h5>
                                                <p>Cold-1</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {humidity && humidity[0] ? humidity[0].cold_aisle_1_humidity_threshold : 0} %</small>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className='col-md-3'>
                                        {humidityIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${humidity && humidity[0] && humidity[0].aisle_cold_2_humidity > humidity[0].cold_aisle_2_humidity_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{humidity && humidity[0] ? humidity[0].aisle_cold_2_humidity : 0} %</h5>
                                                <p>Cold-2</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {humidity && humidity[0] ? humidity[0].cold_aisle_2_humidity_threshold : 0} %</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {humidityIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${humidity && humidity[0] && humidity[0].aisle_hot_1_humidity > humidity[0].hot_aisle_1_humidity_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{humidity && humidity[0] ? humidity[0].aisle_hot_1_humidity : 0} %</h5>
                                                <p>Hot-1</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {humidity && humidity[0] ? humidity[0].hot_aisle_1_humidity_threshold : 0} %</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {humidityIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${humidity && humidity[0] && humidity[0].aisle_hot_2_humidity > humidity[0].hot_aisle_2_humidity_threshold ? `temperature-exceed` : ``}`}>
                                                <h5>{humidity && humidity[0] ? humidity[0].aisle_hot_2_humidity : 0} %</h5>
                                                <p>Hot-2</p>
                                                <div className='threshold'>
                                                    <small>Threshold : {humidity && humidity[0] ? humidity[0].hot_aisle_2_humidity_threshold : 0} %</small>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Humidity */}

                        {/* Generator */}
                        <div className='col-md-6 mb-4'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Generator</h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <GiPowerGenerator />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {generatorIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${generator && generator[0] && generator[0].generator_1_status === 'Not Running' ? `temperature-exceed` : ``}`}>
                                                <h5>{generator && generator[0] ? generator[0].generator_1_status : 'NO DATA'}</h5>
                                                <p>G-1</p>
                                                <div className='threshold'>
                                                    <small>{generator && generator[0] ? `Capacity : ${generator[0].generator_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-6'>
                                        {generatorIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${generator && generator[1] && generator[1].generator_2_status === 'Not Running' ? `temperature-exceed` : ``}`}>
                                                <h5>{generator && generator[1] ? generator[1].generator_2_status : 'NO DATA'}</h5>
                                                <p>G-2</p>
                                                <div className='threshold'>
                                                    <small>{generator && generator[1] ? `Capacity : ${generator[1].generator_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* End Generator */}

                        {/* Electricity */}
                        <div className='col-md-3 mb-4'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Electricity</h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <ImPower />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {electricityIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${electricity && electricity[0] && electricity[0].electricty !== "Available" ? `temperature-exceed` : ``}`}>
                                                <h5>{electricity && electricity[0] ? electricity[0].electricty : 'NO DATA'}</h5>
                                                <p style={{ visibility: 'hidden' }}>dfgfd</p>
                                                <div className='threshold'>
                                                    <small>{electricity && electricity[0] ? 'Electricity' : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Electricity */}

                        {/* Phase */}
                        <div className='col-md-3 mb-4'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Phase Corrector</h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <MdElectricalServices />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {phaseIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className='temperature-box'>
                                                <h5>{phase && phase[0] ? phase[0].phase_status : 'NO DATA'}</h5>
                                                <p style={{ visibility: 'hidden' }}>dfgfd</p>
                                                <div className='threshold'>
                                                    <small>{phase && phase[0] ? `${phase[0].phase_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Phase */}

                        {/* Ups */}
                        <div className='col-md-6'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Power Supply Equipments</h6>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <FaUps />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        {upsIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ups && ups[0] && ups[0].ups_1_status === "Down" ? `temperature-exceed` : ``}`}>
                                                <h5>{ups && ups[0] ? ups[0].ups_1_status : 'NO DATA'}</h5>
                                                <p>UPS-1</p>
                                                <div className='threshold'>
                                                    <small>{ups && ups[0] ? ups[0].ups_description : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {upsIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ups && ups[1] && ups[1].ups_2_status === "Down" ? `temperature-exceed` : ``}`}>
                                                <h5>{ups && ups[1] ? ups[1].ups_2_status : 'NO DATA'}</h5>
                                                <p>UPS-2</p>
                                                <div className='threshold'>
                                                    <small>{ups && ups[1] ? ups[1].ups_description : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {upsIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ups && ups[2] && ups[2].ups_3_status === "Down" ? `temperature-exceed` : ``}`}>
                                                <h5>{ups && ups[2] ? ups[2].ups_3_status : 'NO DATA'}</h5>
                                                <p>UPS-3</p>
                                                <div className='threshold'>
                                                    <small>{ups && ups[2] ? ups[2].ups_description : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {upsIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ups && ups[3] && ups[3].ups_4_status === "Down" ? `temperature-exceed` : ``}`}>
                                                <h5>{ups && ups[3] ? ups[3].ups_4_status : 'NO DATA'}</h5>
                                                <p>UPS-4</p>
                                                <div className='threshold'>
                                                    <small>{ups && ups[3] ? ups[3].ups_description : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Ups */}

                        {/* AC */}
                        <div className='col-md-6'>
                            <div className='main-box'>
                                <div className='row'>
                                    <div className='col-md-6 d-flex align-items-center'>
                                        <h6 className='heading'>Air Conditioner</h6>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className='icon'>
                                            <TbAirConditioning />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        {acIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ac && ac[0] && ac[0].ac_1_status === "Standby" ? `standby-exceed` : ``}`}>
                                                <h5>{ac && ac[0] ? ac[0].ac_1_status : 'NO DATA'}</h5>
                                                <p>AC-1</p>
                                                <div class="form-check form-switch d-flex justify-content-center mb-3">
                                                    <input class="form-check-input"
                                                        type="checkbox" role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        onChange={switchHandleAC1}
                                                        checked={checkedAC1}
                                                        value={ac && ac[0] ? ac[0].ac_1_status : ''}
                                                        disabled={role === 'Guest' ? true : ac && ac[0] ? ac[0].ac_1_status === 'N/A' : false}
                                                    />
                                                    <label class="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                                </div>
                                                <div className='threshold'>
                                                    <small>{ac && ac[0] ? `Capacity : ${ac[0].ac_1_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {acIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ac && ac[0] && ac[0].ac_2_status === "Standby" ? `standby-exceed` : ``}`}>
                                                <h5>{ac && ac[0] ? ac[0].ac_2_status : 'NO DATA'}</h5>
                                                <p>AC-2</p>
                                                <div class="form-check form-switch d-flex justify-content-center mb-3">
                                                    <input class="form-check-input"
                                                        type="checkbox" role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        onChange={switchHandleAC2}
                                                        checked={checkedAC2}
                                                        value={ac && ac[0] ? ac[0].ac_2_status : ''}
                                                        disabled={role === 'Guest' ? true : ac && ac[0] ? ac[0].ac_2_status === 'N/A' : false}
                                                    />
                                                    <label class="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                                </div>
                                                <div className='threshold'>
                                                    <small>{ac && ac[0] ? `Capacity : ${ac[0].ac_2_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {acIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ac && ac[0] && ac[0].ac_3_status === "Standby" ? `standby-exceed` : ``}`}>
                                                <h5>{ac && ac[0] ? ac[0].ac_3_status : 'NO DATA'}</h5>
                                                <p>AC-3</p>
                                                <div class="form-check form-switch d-flex justify-content-center mb-3">
                                                    <input class="form-check-input"
                                                        type="checkbox" role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        onChange={switchHandleAC3}
                                                        checked={checkedAC3}
                                                        value={ac && ac[0] ? ac[0].ac_3_status : ''}
                                                        disabled={role === 'Guest' ? true : ac && ac[0] ? ac[0].ac_3_status === 'N/A' : false}
                                                    />
                                                    <label class="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                                </div>
                                                <div className='threshold'>
                                                    <small>{ac && ac[0] ? `Capacity : ${ac[0].ac_3_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                    <div className='col-md-3'>
                                        {acIsLoading ? <div className='loader-box'>
                                            <Loader />
                                        </div> :
                                            <div className={`temperature-box ${ac && ac[0] && ac[0].ac_4_status === "Standby" ? `standby-exceed` : ``}`}>
                                                <h5>{ac && ac[0] ? ac[0].ac_4_status : 'NO DATA'}</h5>
                                                <p>AC-4</p>
                                                <div class="form-check form-switch d-flex justify-content-center mb-3">
                                                    <input class="form-check-input"
                                                        type="checkbox" role="switch"
                                                        id="flexSwitchCheckChecked"
                                                        onChange={switchHandleAC4}
                                                        checked={checkedAC4}
                                                        value={ac && ac[0] ? ac[0].ac_4_status : ''}
                                                        disabled={role === 'Guest' ? true : ac && ac[0] ? ac[0].ac_4_status === 'N/A' : false}
                                                    />
                                                    <label class="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                                </div>
                                                <div className='threshold'>
                                                    <small>{ac && ac[0] ? `Capacity : ${ac[0].ac_4_description}` : 'NO DATA'}</small>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                {/* Alter Time From */}
                                <form onSubmit={formik.handleSubmit}>
                                    <div className='row d-flex justify-content-center pt-4'>
                                        <div className='col-md-12 col-lg-12'>
                                            <div class="input-group">
                                                <input type="text"
                                                    class="form-control placecolor" style={{ fontSize: '14px' }}
                                                    placeholder={ac && ac[0] && ac[0].alternate_time ? `Current Alternate Time : ${ac && ac[0] && ac[0].alternate_time} Hours` : "Set Alternate Time ( In Hours )"}
                                                    name='altTime'
                                                    disabled={isDisabled}
                                                    value={formik.values.altTime}
                                                    onChange={formik.handleChange} />

                                                {isSubmitShow ?
                                                    <>
                                                        <button type='submit' class="input-group-text btn btn-success" ><BiSave /></button>
                                                        <button type='button' class="input-group-text btn btn-danger ml-2" onClick={alterTimeCancelHandler}><RxCross2 /></button></>
                                                    :
                                                    <button type='button' class="input-group-text btn btn-primary" disabled={role === 'Guest' ? true : false} onClick={alterTimeHandler}><FaPenAlt /></button>
                                                }
                                            </div>
                                            {formik.touched.altTime && formik.errors.altTime ? (
                                                <div className='text-danger'>{formik.errors.altTime}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </form>
                                {/* End Alter Time From */}
                            </div>
                        </div>
                        {/* End AC */}

                    </div>
                </div>
            </section>

        </>
    )
}
