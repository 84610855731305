import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './modules/users/login/Login';
import Register from './modules/users/register/Register';
import { PrivateRoutes } from './routes';
import { Dashboard } from './modules/Pages/Dashboard';
import { PopDashboard } from './modules/Pages/PopDashboard';
import { Device } from './modules/Pages/device/Device';
import Email from './modules/email/email';
import { PopDetailsTable } from './modules/Pages/PopDetailsTable';
import { PhaseDetailsTable } from './modules/Pages/PhaseDetailsTable';
import { AlarmPopDetailsTable } from './modules/Pages/AlarmPopDetailsTable';
import { ElectricityDetailsTable } from './modules/Pages/ElectricityAlarmDetailsTable';
import { TemperatureDetailsTable } from './modules/Pages/TemperatureAlarmDetails';
import { UpsDetailsTable } from './modules/Pages/UpsAlarmDetailsTable';
import { GenaratorRunningDetailsTable } from './modules/Pages/GenaratorRunningDetailsTable';
import GenaratorNotRunningDetailsTable from './modules/Pages/GenaratorNotRunningDetailsTble';
import { UserList } from './modules/users/userList/UserList';
import { DeviceList } from './modules/Pages/reports/DeviceList';
import { Log } from './modules/Pages/reports/Log';

import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';
import { DeviceResponseAlarmTable } from './modules/Pages/DeviceResponseAlarmTable';







function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route>
          <Route path="/" element={<Login />} />
          
        </Route>

        <Route element={<PrivateRoutes />} >
          <Route path='/dashboard' element={<Dashboard />} />

          <Route path="/register" element={<Register />} />

          <Route path='/userlist' element={<UserList />} />
          <Route path="/popdashboard/:popid" element={<PopDashboard />} />

          <Route path='/dashboard/totalpopdetails' element={<PopDetailsTable />} />

          <Route path='/dashboard/phasealarmdetails' element={<PhaseDetailsTable />} />
          <Route path='/dashboard/popalarmdetails' element={<AlarmPopDetailsTable />} />

          <Route path='/dashboard/upsalarmdetails' element={<UpsDetailsTable />} />
          <Route path='/dashboard/electricityalarmdetails' element={<ElectricityDetailsTable />} />
          <Route path='/dashboard/temperaturealarmdetails' element={<TemperatureDetailsTable />} />

          <Route path='/dashboard/genaratorrunning' element={<GenaratorRunningDetailsTable />} />
          <Route path='/dashboard/genaratornotrunning' element={<GenaratorNotRunningDetailsTable />} />
          <Route path='/dashboard/deviceresponsealarmdetails' element={<DeviceResponseAlarmTable />} />

          <Route path='/device' element={<Device />} />

          <Route path='/email' element={<Email />} />

          <Route path='/reports/devicelist' element={<DeviceList />} />
          <Route path='/reports/log' element={<Log />} />


        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
