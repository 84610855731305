import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Swal from 'sweetalert2';

export const GeneratorEdit = ({ generatorid, modalClose, tableRefresh }) => {


    const [pop_name, setPop_name] = useState("")
    const [arduino_name, setArduino_name] = useState("")
    const [generator_id, setGenerator_id] = useState("")
    const [generator_description, setGenerator_description] = useState("")
    const [generator_status, setGenerator_status] = useState("")
    const [validationError, setValidationError] = useState({})

    useEffect(() => {
        fetchProduct();
    }, [])

    const fetchProduct = async () => {
        await axios.get(`https://pab.race.net.bd/api/generatoredit/${generatorid}`).then(({ data }) => {
            const { pop_name, arduino_name, generator_id, generator_description, generator_status } = data.generator
            setPop_name(pop_name)
            setArduino_name(arduino_name)
            setGenerator_id(generator_id)
            setGenerator_description(generator_description)
            setGenerator_status(generator_status)
        }).catch(({ response: { data } }) => {
            Swal.fire({
                text: data.message,
                icon: "error"
            })
        })
    }

    const updatePop = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('_method', 'PATCH');
        formData.append('pop_name', pop_name)
        formData.append('arduino_name', arduino_name)
        formData.append('generator_id', generator_id)
        formData.append('generator_description', generator_description)
        formData.append('generator_status', generator_status)


        await axios.post(`https://pab.race.net.bd/api/generatorupdate/${generatorid}`, formData).then(({ data }) => {
            Swal.fire({
                icon: "success",
                text: data.message
            })
            modalClose();
            tableRefresh();
        }).catch(({ response }) => {
            if (response.status === 422) {
                setValidationError(response.data.errors)
            } else {
                Swal.fire({
                    text: response.data.message,
                    icon: "error"
                })
            }
        })
    }

    return (
        <div className="form-wrapper">
            {
                Object.keys(validationError).length > 0 && (
                    <div className="row">
                        <div className="col-12">
                            <div className="alert alert-danger">
                                <ul className="mb-0">
                                    {
                                        Object.entries(validationError).map(([key, value]) => (
                                            <li key={key}>{value}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                )
            }
            <Form onSubmit={updatePop}>
                <Row>
                    <Col>
                        <Form.Group controlId="pop_name">
                            <Form.Label>Pop Name</Form.Label>
                            <Form.Control disabled type="text" value={pop_name} onChange={(event) => {
                                setPop_name(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="arduino_name">
                            <Form.Label>Arduino Name</Form.Label>
                            <Form.Control disabled type="text" value={arduino_name} onChange={(event) => {
                                setArduino_name(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="generator_id">
                            <Form.Label>Generator ID</Form.Label>
                            <Form.Control type="text" value={generator_id} onChange={(event) => {
                                setGenerator_id(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <Form.Group controlId="generator_description">
                            <Form.Label>Generator Description</Form.Label>
                            <Form.Control type="text" value={generator_description} onChange={(event) => {
                                setGenerator_description(event.target.value)
                            }} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" className="float-right" type="submit">
                    Update
                </Button>
            </Form>
        </div>
    )
}
