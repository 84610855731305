import * as Yup from 'yup';

const loginFromValidation = Yup.object({
  username: Yup.string()
      .required('Required *'),
  password: Yup.string()
      .required('Required *'),
});

const popAddNameFromValidation = Yup.object({
  popName: Yup.string()
    .required('Required *')
});

const popAddFromValidation = Yup.object({
  pop_name: Yup.string()
    .required('Required *'),
  cold_aisle_1_temperature_threshold: Yup.number()
    .typeError('Please type must number')
    .required('Required *'),
  cold_aisle_1_humidity_threshold: Yup.number()
    .typeError('Please type must number')
    .required('Required *'),

  // cold_aisle_2_temperature_threshold: Yup.number()
  //   .typeError('Please type must number')
  //   .required('Required *'),
  // cold_aisle_2_humidity_threshold: Yup.number()
  //   .typeError('Please type must number')
  //   .required('Required *'),

  // hot_aisle_1_temperature_threshold: Yup.number()
  //   .typeError('Please type must number')
  //   .required('Required *'),
  // hot_aisle_1_humidity_threshold: Yup.number()
  //   .typeError('Please type must number')
  //   .required('Required *'),

  // hot_aisle_2_temperature_threshold: Yup.number()
  //   .typeError('Please type must number')
  //   .required('Required *'),
  // hot_aisle_2_humidity_threshold: Yup.number()
  //   .typeError('Please type must number')
  //   .required('Required *'),

});

const ArduinoAddFromValidation = Yup.object({
  pop_id: Yup.string()
    .required('Required *'),
  arduino_name: Yup.string()
    .required('Required *'),
});

const ACAddFromValidation = Yup.object({
  pop_id: Yup.string()
    .required('Required *'),
  arduino_id: Yup.string()
    .required('Required *'),
  ac_id: Yup.number()
    .typeError('Please type must number')
    .required('Required *'),
  // ac_description: Yup.number()
  //   .required('Required *')
  //   .typeError('Please type must number')
});

const UpsAddFromValidation = Yup.object({
  pop_id: Yup.string()
    .required('Required *'),
  arduino_id: Yup.string()
    .required('Required *'),
  ups_id: Yup.number()
    .typeError('Please type must number')
    .required('Required *'),
  ups_description: Yup.string()
    .required('Required *')
});

const GeneratorAddFromValidation = Yup.object({
  pop_id: Yup.string()
    .required('Required *'),
  arduino_id: Yup.string()
    .required('Required *'),
  generator_id: Yup.number()
    .typeError('Please type must number')
    .required('Required *'),
  // generator_description: Yup.number()
  //   .required('Required *')
  //   .typeError('Please type must number')
});

const PhaseAddFromValidation = Yup.object({
  pop_id: Yup.string()
    .required('Required *'),
  arduino_id: Yup.string()
    .required('Required *'),
  phase_id: Yup.number()
    .required('Required *'),
  phase_description: Yup.string()
    .required('Required *')
});

const AlterTimeFromValidation = Yup.object({
  altTime: Yup.number()
    .positive("Must input positive number")
    .integer("Must input number")
    .typeError('Please type number')
    .required('Required *'),

});

const ReportsLogFromValidation = Yup.object({
  popname: Yup.string()
    .required('Required *'),
  devicelist: Yup.string()
    .required('Required *'),
  formDate: Yup.date()
    .typeError('Please enter a date type')
    .required('Required *'),
  toDate: Yup.date()
    .typeError('Please enter a date type')
    .required('Required *'),
});

export { popAddFromValidation, ArduinoAddFromValidation, ACAddFromValidation, UpsAddFromValidation, GeneratorAddFromValidation, AlterTimeFromValidation, ReportsLogFromValidation, PhaseAddFromValidation, popAddNameFromValidation,loginFromValidation }